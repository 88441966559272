import classNames from 'classnames'
import { SVGAttributes } from 'react'

export function StatusCircleIcon(
  props: {
    state?: string
    label?: string
    bgClassNames?: string | string[]
    bgFill?: string
    bgStroke?: string
  } & SVGAttributes<SVGElement>
) {
  const {
    bgClassNames,
    bgFill,
    bgStroke,
    state,
    className,
    label,
    ...svgProps
  } = props
  const colors: Record<string, string> = {
    info: 'blue',
    error: 'red',
    working: 'orange',
    idle: 'gray',
    success: 'green',
    default: 'gray',
  }

  const statusBgColor = bgFill ?? colors[state ?? 'default'] ?? 'gray'

  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      className={classNames(
        'icon',
        'icon-circle',
        `icon-circle-${state}`,
        className
      )}
      viewBox='0 0 20 20'
      fill='none'
      aria-hidden='true'
      aria-label={`status: ${state}`}
      {...svgProps}
    >
      <circle
        className={classNames('icon-background', bgClassNames)}
        cx='10'
        cy='10'
        r='9'
        strokeWidth='1px'
        stroke={bgStroke ?? 'none'}
        fill={statusBgColor ?? 'currentColor'}
      >
        <title>{label ?? state}</title>
      </circle>
    </svg>
  )
}
