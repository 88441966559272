import React, { useMemo } from "react"


function FeatureFlag(props: { label: string; flagType: string; order?: number; className?: string; key?:string  }) {
  return (
    <div className={`text-[11px] feature-flag feature-flag-${props.flagType} ${props.className} my-1 rounded-[8px] bg-yellow-400 xopacity-90 border border-gray-600
         text-black leading-4 px-2`}>
      <span className="uppercase">{props.label}</span>
    </div>)
}

export default React.memo(FeatureFlag)
