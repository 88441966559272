import { AnchorHTMLAttributes } from 'react'

export function ActionButton(
  props: {
    actionId: string
    label: string
    url: string
    className?: string
  } & AnchorHTMLAttributes<HTMLAnchorElement>
) {
  const { actionId, label, url, className, style, ...attr } = props
  const bgColor =
    actionId === 'enroll'
      ? 'bg-white hover:bg-transparent'
      : 'bg-blue hover:bg-transparent'
  const txtColor =
    actionId === 'enroll'
      ? 'text-slate-600 hover:text-white'
      : 'text-white hover:text-white'

  const defaultActionButtonStyle: React.CSSProperties = {
    borderWidth: '2px',
    borderRadius: '35px',
    borderColor: 'white',
    textTransform: 'uppercase',
    lineHeight: 1,
    height: '35px',
    fontSize: '0.8rem',
    padding: '0.1em 1em 0',
    display: 'flex',
    placeItems: 'center',
    fontWeight: 600,
    letterSpacing: '1px',
    boxSizing: 'border-box',
  }

  const actionButtonStyle = { ...defaultActionButtonStyle, ...style }

  return (
    <a
      className={`pill flex-grow-0 text-sm no-underline ${bgColor} ${txtColor} ${className}`}
      href={url}
      style={actionButtonStyle}
      {...attr}
    >
      {label}
    </a>
  )
}

export function ActionButtonSet(
  props: {
    actions: {
      [key: string]: {
        title: string
        text: string
        url: string
        className?: string
      }
    }
  } & AnchorHTMLAttributes<HTMLAnchorElement>
) {
  const { actions, ...attr } = props
  const viewModels = Object.entries(actions).map(([key, value]) => ({
    actionId: key,
    label: value.title,
    url: value.url,
    className: value.className,
  }))

  const buttons = viewModels.map(vm => (
    <ActionButton
      key={vm.actionId}
      actionId={vm.actionId}
      label={vm.label}
      url={vm.url}
      {...attr}
    />
  ))
  return <>{buttons}</>
}
