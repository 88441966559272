/* eslint-disable @typescript-eslint/no-namespace */
import { AlgoliaError, connectStateResults } from "react-instantsearch-core";
import { useInstantSearch } from "react-instantsearch-hooks-web";


const SearchError = (props:{
  // searchState: SearchState, 
  // searchResults: SearchResults<BasicDoc>, 
  // allSearchResults: SearchResults<BasicDoc>,
  searching: boolean;
  isSearchStalled: any;
  // searchingForFacetValues: any;
  error?: AlgoliaError;
}) => {
 
  const {error, searching, isSearchStalled} = props

  if (error) {
    return (
      <>
        <div className="bg-red-700 text-white rounded-md shadow-md p-2 px-4 w-full">Search error: {error.message}</div>
        <span className="p-2">Searching: [{searching === false ? "False" : searching === true ? "True" : "Unknown"}]</span>
        <span className="p-2">IsStalled: [{isSearchStalled === false ? "False" : isSearchStalled === true ? "True" : "Unknown"}]</span>
      </>)
  } 

  if (isSearchStalled) {
    return <div>search is stalled..</div>
  } 

  if (searching) {
    return <div>searching...</div>
  }
  
  return <></>


}

const CustomSearchError = connectStateResults(SearchError)

export { CustomSearchError };





namespace ReactHooks {

  export function SearchError() {
    const { error, status } = useInstantSearch({ catchError: true })


    if (error) {
      
      return <>({status}) {error.name}: {error.message}</>
    }

    return <>({status})</>
  }

}

