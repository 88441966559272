import Color from 'color'
import { CSSProperties } from 'react'


export class LinearGradient {

  public readonly colorStart: Color
  public readonly colorEnd: Color
  
  constructor(colorStart:Color, colorEnd:Color) {
    this.colorStart = colorStart
    this.colorEnd = colorEnd
  }

  public toString() {
    const cssColorStart = this.colorStart.rgb().toString()
    const cssColorEnd= this.colorEnd.rgb().toString()
    return `linear-gradient(${cssColorStart}, ${cssColorEnd} 75%)`
  } 
}

export class UrlImage {
  url: string

  constructor(url:string) {
    this.url = url
  }

  public toString() { return `url(${this.url})` }
}

export interface IBackgroundLayer {
  image:string,
  position:string,
  size:string,
  blendMode:string,
}

export class CompositeBackground {
  public layers: Array<IBackgroundLayer>

  constructor (layers?: Array<IBackgroundLayer>) {
    this.layers = [...(layers ?? [])]
  }

  public AddLayerBelow(layer:IBackgroundLayer) {
    this.layers = [...this.layers, layer]
  }

  public AddLayerAbove(layer:IBackgroundLayer) {
    this.layers = [layer, ...this.layers]
  }

  public Clear() {
    this.layers = []
  }

  public RemoveLayer(index:number) {
    const result = [...this.layers]
    const removed = result.splice(index)
    this.layers = result
    return removed
  }

  public InsertLayer(position:number, layer:IBackgroundLayer ) {
    this.layers = [
      ...this.layers.slice(0, position),
      layer,
      ...this.layers.slice(position)
    ]
  }

  public toCss() {
    return {
      backgroundSize: this.layers.map(layer => layer.size).join(", "),
      backgroundPosition: this.layers.map(layer => layer.position).join(", "),
      backgroundImage: this.layers.map(layer => layer.image).join(", "),
      backgroundBlendMode: this.layers.map(layer => layer.blendMode).join(", ").toString(),
    } as CSSProperties
  }
}

export class ColorizedBackgroundImage extends CompositeBackground {
  constructor(imageUrl:string, color1:Color, color2?:Color) {
    super()
    
    color2 ??= color1

    this.AddLayerAbove({
      position: "center",
      size: "cover",
      blendMode: "normal",
      image: new LinearGradient(color1, color2).toString(),
    })

    this.AddLayerAbove({
      position: "center",
      size: "cover",
      blendMode: "normal",
      image: new UrlImage(imageUrl).toString(),
    })

    this.AddLayerAbove({
      position: "center",
      size: "cover",
      blendMode: "saturation",
      image: new LinearGradient(Color("black"), Color("black")).toString(),
    })

    this.AddLayerAbove({
      position: "center",
      size: "cover",
      blendMode: "multiply",
      image: new LinearGradient(color1.alpha(1), color2.alpha(1)).toString(),
    })

    this.AddLayerAbove({
      position: "center",
      size: "cover",
      blendMode: "screen",
      image: new LinearGradient(color1.alpha(0.1), color2.alpha(1)).toString(),
    })
  }
}

