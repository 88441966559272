import classNames from 'classnames'
import React, { Children, ReactNode, useRef, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import altCardImageSvg from '../../assets/blue-illinois-square.svg'
import FeatureFlag from './FeatureFlag'

export interface ISearchCardProps {
  entityId: string // entity ID

  title: string // card title
  imageUrl: string // card image
  category: string // entity category
  description: string // body
  url: string // details url
  externalUrl?: string // origin url

  features?: { label: string; type: string }[]
  featureFlags?: ReactNode[]

  data: any // entity data

  // state
  selected?: boolean

  // behavior
  navOnClick?: boolean

  // actions
  onSelected?: (id: string, selected: boolean, sender: any) => void // handle selection state change event
  onFavorite?: (id: string, selected: boolean, sender: any) => void // handle favorite state change event
}

/**
 * SearchCard React Component
 * @param props
 * @returns
 */
export function SearchCard(
  props: ISearchCardProps & React.HTMLAttributes<HTMLDivElement>
) {
  // console.log("Search Card VM", props);

  const {
    entityId,
    title,
    imageUrl,
    description,
    url,
    externalUrl,
    category,
    data,
    features,
    navOnClick = true,
    featureFlags,

    onSelected,
  } = props

  // const navOnClick = props.navOnClick ?? true

  /*
    const createFeatureFlags = (): ReactNode[] => {
        const propFeatureFlags = props.featureFlags ?? [];

        var initValue = [] as ReactNode[];

        const childFeatureFlags = arrayChildren
            .reduce<ReactNode[]>((agg, child) => {
                if (React.isValidElement(child) && child.type == FeatureFlag.name)
                    agg = [...agg, child];
                return agg;
            }, initValue);

        const allFeatures = [[], ...childFeatureFlags, ...propFeatureFlags ?? []]; //.map((n,i) => {(n as ReactElement).key = i; return n})
        return allFeatures;
    };
    */
  const debug = false

  const navigate = useNavigate() // react router hook for navigation

  // Local State
  const [isSelected, setSelected] = useState(false)
  const [isFavorite, setFavorite] = useState(false)

  // HTML reference
  const searchCardRef = useRef<HTMLDivElement>(null)

  // Effects
  // useEffect(
  //   // TODO: Check for favorite status.
  //   // clean-up function
  //   () => { /* no-op */},
  //   []
  // )

  // Generate FeatureFlags from props.features
  const flags =
    features?.map(f => (
      <FeatureFlag
        key={`${f.type}-${f.label}`}
        label={f.label}
        flagType={f.type}
      />
    )) ?? []

  // Split children elements into "FeatureFlags, children"
  const arrayChildren = Children.toArray(props.children)
  // const a = ExtractElementsOfType(arrayChildren, FeatureFlag)
  const [childFeatureFlags, children] = arrayChildren.reduce<ReactNode[][]>(
    ([featureFlags, unmatched], elem) => {
      if (
        React.isValidElement(elem) &&
        (elem as any)?.type?.name === 'FeatureFlag'
      ) {
        featureFlags.push(elem as any)
      } else {
        unmatched.push(elem)
      }
      return [featureFlags, unmatched]
    },
    [new Array<ReactNode>(), new Array<ReactNode>()]
  )

  // Join all FeatureFlags (good luck with unique keys!)
  const allFeatures = [
    ...flags, // flags generated from props.features {label:string,type:string}[]
    ...childFeatureFlags, // flags inferred from children
    ...(featureFlags ?? []), // flags from props.featureFlags ReactNode[]
  ]

  // const featureFlags = arrayChildren.filter(child => (child as any).type.name == "FeatureFlag")
  function handleClick(e: React.MouseEvent<HTMLDivElement>) {
    // toggle selection state
    const newState = !isSelected
    setSelected(newState)
    searchCardRef.current?.focus()

    if (onSelected) onSelected(entityId, newState, searchCardRef.current)
    // navOnClick && navigate(url);

    const { onClick } = props
    if (onClick) onClick(e)
  }

  // button behavior
  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    // console.log(`handleKeyDown: ${e.key}`);
    if (e.key === 'Enter' || e.key === ' ') {
      searchCardRef.current?.click()
    }
  }

  return (
    <div
      ref={searchCardRef}
      tabIndex={0}
      role='button'
      className={classNames(
        'search-card',
        'flex flex-col text-left',
        { selected: isSelected },
        isSelected ? 'xoutline-dashed xoutline-amber-400 xoutline-offset-2' : ''
      )}
      onClick={handleClick}
      onKeyDown={handleKeyDown}
    >
      <div className='relative bg-blue-800'>
        <div className='xright-1 xbottom-0 absolute right-[-8px] top-0 z-10'>
          {allFeatures}
        </div>
        <img
          className='aspect-[16/9] w-full object-cover'
          src={imageUrl}
          alt=''
          data-x='/assets/react-typesense-nd-search/blue-illinois-square.svg'
        />
      </div>

      {/* {arrayChildren.map((child,i) => {
                const reactElement = (child as React.ReactElement)
                const elementType = reactElement?.type
                const elementTypeName = (reactElement?.type as any).name
                return (<div key={i}><>{i}: {(typeof child)} {elementType} {elementTypeName}</></div>)
            })} */}

      <div className='body flex h-[158px] flex-grow flex-col overflow-y-hidden'>
        <div className='search-card-category bg-slate-300 px-2 py-1 text-xs'>
          <span>{category}</span>
        </div>

        <div className='search-card-title min-h-[50px] px-2 py-1 text-sm font-semibold leading-5'>
          <Link to={`/offerings/${entityId}`}>{title}</Link>
        </div>

        <div className='search-card-description px-2 py-0 text-xs leading-4 line-clamp-4'>
          {description}
        </div>
        {/* {props.children} */}
        {children}
      </div>

      <div
        className='search-card-footer footer flex border-t'
        data-entity-id={entityId}
      >
        <div className='mr-auto px-2 py-1 text-xs'>
          <Link
            to={`/offerings/${entityId}`}
            onClick={e => e.stopPropagation()}
          >
            DETAILS
          </Link>
        </div>
        {externalUrl && (
          <div className='px-2 py-1 text-xs'>
            <a href={externalUrl} onClick={e => e.stopPropagation()}>
              ENROLL
            </a>
          </div>
        )}
      </div>

      {debug && (
        <>
          <div className='hidden max-h-[180px] overflow-y-hidden'>
            <div className='break-all text-xs'>{JSON.stringify(data)}</div>
          </div>
          <div className='hidden'>
            <img src={altCardImageSvg} />
          </div>
        </>
      )}
    </div>
  )
}

/**
 * Divides an array of ReactNodes into ones matching a given ReactElement type
 * @param array An array of ReactNode
 * @param type A ReactComponentType to match by type
 * @returns a tuple of [matched, unmatched]
 * @example const [matched,unmatched] = ExtractElementsOfType(Children.toArray(props.children), MyComponent)
 */
function ExtractElementsOfType<T>(array: ReactNode[], type: { name: string }) {
  const childFeatureFlags = array.reduce<ReactNode[][]>(
    ([matched, unmatched], child) => {
      const isOftype = React.isValidElement(child) && child.type === type.name
      if (isOftype) {
        return [[...matched, child], unmatched]
      }
      return [matched, [...unmatched, child]]
    },
    [[], []]
  )
  return childFeatureFlags
}
