import classnames from 'classnames'
import { ReactNode } from 'react'
import { NavLink } from 'react-router-dom'

/**
 * App Footer Component
 * @param props
 * @returns JSX Component
 */
export function Footer(
  props: { display?: boolean; children: ReactNode | ReactNode[] } & any
) {
  //const notify = () => toast.info("Info notification")

  const navButtonCss = 'basis-full sm:basis-auto'

  const appTitle = 'CITL Non Credit Course Search'
  const versionNumber = import.meta.env.VITE_VERSION_ID
  const publicUrl = import.meta.env.VITE_PUBLIC_URL
  const buildDate = import.meta.env.VITE_BUILD_DT

  const { children, display = true } = props

  if (!display) return <></>

  return (
    <footer
      className='search-app-footer 
        flex flex-wrap justify-center gap-2 border-t border-gray-400/40 p-4 
        md:flex-nowrap'
    >
      {/* Left Column (build data) */}
      <div className='mr-auto'>
        <div className='text-xs'>
          <div>{appTitle}</div>
          <div>version: {versionNumber} (typesense)</div>
          <div>build date: {buildDate}</div>
        </div>
      </div>

      {/* Center Column */}
      <div className='hidden'>
        {publicUrl}
        {children}
      </div>

      {/* Right Column (navigation) */}
      <div className='flex flex-wrap gap-2'>
        {/* <NavLink className={classNames(navButtonCss,"hidden")} to="/"  end >Home</NavLink> */}
        <NavLink
          className={classnames(navButtonCss, 'text-blue-700')}
          to='/search'
          end
        >
          Return to Search
        </NavLink>
        {/* Testing Links  */}
        {/* <NavLink className={classNames(navButtonCss,"hidden")} to="/favorites" end >Favorites</NavLink> */}
        {/* <NavLink className={classNames(navButtonCss,"hidden")} to="/offerings/course~TE5yWMG5EeikXg7aK_-m0g"  end >coursera-1</NavLink> */}
        {/* <NavLink className={classNames(navButtonCss,"hidden")} to="/offerings/117225" end >canvas-1</NavLink> */}
        {/* <NavLink className={classNames(navButtonCss,"hidden")} to="/error-404" end >Error-404</NavLink> */}
        {/* <button className={classNames(navButtonCss,"flex items-center bg-gray-400 border-0 px-2")} onClick={(e) => {e.preventDefault(); notify()}}>toast</button> */}
      </div>
    </footer>
  )
}
