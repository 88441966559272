import { Item } from './models/ecommerce'

type NamedEvent<T = any> = { event: string } & T

type ECommerceEvent<T = any> =
  | (NamedEvent & { ecommerce: T })
  | { ecommerce: null }

export const analytics = () => {
  window.dataLayer = window.dataLayer || []
  const dataLayer = (window.dataLayer = window.dataLayer || [])

  const gtag = (...args: Array<any>) => {
    dataLayer.push(...args)
  }

  //const gtag2 = () => {
  //dataLayer.push(arguments)
  //}

  const setUserId = (userId: string | null) => {
    gtag({
      user_id: userId ?? undefined,
    })
  }

  const sendEvent = (name: string, data: any) => {
    gtag({ event: name, ...data })
  }

  /* Item-List Interactions */
  const selectItem = (
    item: Item,
    item_list_id?: string | null,
    item_list_name?: string | null
  ) => {
    const payload: ECommerceEvent = {
      event: 'select_item',
      ecommerce: {
        item_list_id: item_list_id ?? undefined,
        item_list_name: item_list_name ?? undefined,
        items: [item],
      },
    }

    gtag('event', payload.event, payload)
  }

  const viewItemList = (
    items: Array<Item>,
    item_list_id?: string | null,
    item_list_name?: string | null
  ) => {
    const payload: ECommerceEvent = {
      event: 'view_item_list',
      ecommerce: {
        item_list_id: item_list_id ?? undefined,
        item_list_name: item_list_name ?? undefined,
        items,
      },
    }
    gtag('event', payload.event, payload)
  }

  /* Item Interactions */
  const viewItem = (
    items: Array<Item>,
    value?: number | null,
    currency?: string | null
  ) => {
    const payload: ECommerceEvent = {
      event: 'view_item',
      ecommerce: {
        //value: value ?? undefined,
        //currency: value != null ? currency ?? 'USD' : undefined,
        items,
      },
    }

    console.log(`sending event: ${payload.event}`, payload)

    //window.dataLayer = window.dataLayer || []
    //window.dataLayer.push(payload)
    ;(window as any).gtag('event', payload.event, payload.ecommerce)
  }

  const addToCart = (
    items: Array<Item>,
    value?: number | null,
    currency?: string | null
  ) => {
    const payload: ECommerceEvent = {
      event: 'add_to_cart',
      ecommerce: {
        value: value ?? undefined,
        currency: value != null ? currency ?? 'USD' : undefined,
        items,
      },
    }
    gtag('event', payload.event, payload)
  }

  const removeFromCart = (
    items: Array<Item>,
    value?: number | null,
    currency?: string | null
  ) => {
    const payload: ECommerceEvent = {
      event: 'remove_from_cart',
      ecommerce: {
        value: value ?? undefined,
        currency: value != null ? currency ?? 'USD' : undefined,
        items,
      },
    }
    gtag('event', payload.event, payload)
  }

  const viewCart = (
    items: Array<Item>,
    value?: number | null,
    currency?: string | null
  ) => {
    const payload: ECommerceEvent = {
      event: 'view_cart',
      ecommerce: {
        value: value ?? undefined,
        currency: value != null ? currency ?? 'USD' : undefined,
        items,
      },
    }
    gtag('event', payload.event, payload)
  }

  const addToWishlist = (
    items: Array<Item>,
    value?: number | null,
    currency?: string | null
  ) => {
    const payload: ECommerceEvent = {
      event: 'add_to_wishlist',
      ecommerce: {
        value: value ?? undefined,
        currency: value != null ? currency ?? 'USD' : undefined,
        items,
      },
    }
    gtag('event', payload.event, payload)
  }

  const beginCheckout = (
    items: Array<Item>,
    value?: number | null,
    currency?: string | null,
    coupon?: string | null
  ) => {
    const payload: ECommerceEvent = {
      event: 'begin_checkout',
      ecommerce: {
        value: value ?? undefined,
        currency: value != null ? currency ?? 'USD' : undefined,
        items,
        coupon: coupon ?? undefined,
      },
    }
    gtag('event', payload.event, payload)
  }

  const purchase = (
    items: Array<Item>,
    transactionId: string,
    value?: number | null,
    shipping?: number | null,
    tax?: number | null,
    currency?: string | null,
    coupon?: string | null
  ) => {
    const payload: ECommerceEvent = {
      event: 'purchase',
      ecommerce: {
        transactionId,
        value: value ?? undefined,
        shipping: shipping ?? undefined,
        tax: tax ?? undefined,
        currency: value != null ? currency ?? 'USD' : undefined,
        items,
        coupon: coupon ?? undefined,
      },
    }
    gtag('event', payload.event, payload)
  }

  const clearEcommerceData = () => {
    const payload: ECommerceEvent = { ecommerce: null }
    gtag('event', payload.event, payload)
  }

  const selectPromotion = (
    items: Array<Item>,
    creative_name?: number | null,
    creative_slot?: string | null,
    promotion_id?: string | null,
    promotion_name?: string | null
  ) => {
    const payload: ECommerceEvent = {
      event: 'select_promotion',
      ecommerce: {
        creative_name: creative_name ?? undefined,
        creative_slot: creative_slot ?? undefined,
        promotion_id: promotion_id ?? undefined,
        promotion_name: promotion_name ?? undefined,
        items,
      },
    }
    gtag('event', payload.event, payload)
  }

  const signup = (method?: string | null) => {
    const payload: NamedEvent = {
      event: 'signup',
      method: method ?? undefined,
    }
    gtag(payload)
  }

  const generateLead = (value?: number | null, currency?: string | null) => {
    const payload: NamedEvent = {
      event: 'generate_lead',
      value: value ?? undefined,
      currency: value != null ? currency ?? 'USD' : undefined,
    }
    gtag('event', payload.event, payload)
  }

  const joinGroup = (group_id?: string | null) => {
    const payload: NamedEvent = {
      event: 'join_group',
      method: group_id ?? undefined,
    }
    gtag('event', payload.event, payload)
  }

  const login = (method?: string | null) => {
    const payload: NamedEvent = {
      event: 'login',
      method: method ?? undefined,
    }
    gtag('event', payload.event, payload)
  }

  const selectContent = (content_type?: string, content_id?: string) => {
    const payload: NamedEvent = {
      event: 'select_content',
      content_type,
      content_id,
    }
    gtag('event', payload.event, payload)
  }

  const search = (search_term: string) => {
    const payload: NamedEvent = {
      event: 'search',
      search_term,
    }
    gtag('event', payload.event, payload)
  }

  const share = (
    method?: string | null,
    content_type?: string | null,
    item_id?: string | null
  ) => {
    const payload: NamedEvent = {
      event: 'share',
      method: method ?? undefined,
      content_type: content_type ?? undefined,
      item_id: item_id ?? undefined,
    }
    gtag('event', payload.event, payload)
  }

  return {
    displayName: 'GA Analytics Engine',
    sendEvent,
    selectItem,
    viewItemList,
    viewItem,
    addToCart,
    removeFromCart,
    viewCart,
    addToWishlist,
    beginCheckout,
    purchase,
    clearEcommerceData,
    selectPromotion,
    signup,
    generateLead,
    joinGroup,
    login,
    selectContent,
    search,
    share,
  }
}

export type AnalyticsBroker = ReturnType<typeof analytics>
