/**
 * A simple page proving information about Noncredit Courses
 * @returns
 */
export default function InfoPage() {
  return (
    <div className='citl-info-page mx-auto w-full min-w-[280px] max-w-5xl flex-grow flex-col'>
      <h1 className='mb-2 border-b-0  text-3xl'>About Noncredit Courses</h1>

      <h2 id='noncredit-courses' className='text-2xl'>
        Noncredit Courses
      </h2>

      <p className=''>
        You don&apos;t have to be formally admitted to the University of
        Illinois to access an Illinois education!
      </p>

      <p className=''>
        Illinois offers noncredit online courses to the public through Canvas,
        Coursera, EdX, and many more. These online, flexible courses allow you
        take them from anywhere in the world at your own pace.
      </p>

      <p className=''>
        Noncredit courses are offered in a wide range of topics. Each course has
        its own unique learning outcome, competencies, and assessments.
      </p>

      <p className=''>
        Courses offer you the opportunity to gain general knowledge, stay on top
        of emerging trends, learn a new skill, upgrade existing skills, and
        develop personal interests. Find a course that&apos;s right for you and
        grow and improve professionally or personally.
      </p>

      <p className=''>
        Noncredit courses aren&apos;t financial aid eligible. They also
        aren&apos;t applicable toward a degree, and they will not transfer.
        However, many courses will provide a badge or certificate of
        completion*.
      </p>

      <h2 id='course-platforms' className='text-2xl'>
        Platforms
      </h2>

      <p className=''>
        Canvas, Coursera, EdX, and others are online platforms for learning
        experiences. The University of Illinois uses these and other technology
        platforms to offer learning opportunities developed at Illinois to
        learners around the world. When you select a learning opportunity, you
        will be directed to the website to enroll in the course.
      </p>

      <p className='italic'>
        *Completion credentialing varies by course and provider.
      </p>
    </div>
  )
}
