import TypesenseInstantsearchAdapter, {
  TypesenseInstantsearchAdapterOptions,
} from 'typesense-instantsearch-adapter'
import { ConfigurationOptions } from 'typesense/lib/Typesense/Configuration'

const DEFAULT_TYPESENSE_SEARCH_API_KEY = '7EwDJLF8foVnajLCzbjtHL0SNO0nX0ph'
const DEFAULT_TYPESENSE_HOST = 'ptkuq0exrilj23vgp-1.a1.typesense.net'

export const TYPESENSE_SERVER_CONFIG: ConfigurationOptions = {
  apiKey:
    import.meta.env.VITE_TYPESENSE_SEARCH_API_KEY ??
    DEFAULT_TYPESENSE_SEARCH_API_KEY,
  // '7EwDJLF8foVnajLCzbjtHL0SNO0nX0ph',
  //apiKey: '7EwDJLF8foVnajLCzbjtHL0SNO0nX0ph', // Be sure to use an API key that only allows searches, in production
  nodes: [
    {
      host: import.meta.env.VITE_TYPESENSE_HOST ?? DEFAULT_TYPESENSE_HOST,
      port: 443,
      protocol: 'https',
    },
  ],
  connectionTimeoutSeconds: 1,
  numRetries: 8,
  cacheSearchResultsForSeconds: 2 * 60,
  // logLevel?: LogLevelDesc;
  // logger?: Logger;
}

// DEFAULT SEARCH CONFIG
export const TYPESENSE_INSTANCE_SEARCH_ADAPTER_CONFIG: TypesenseInstantsearchAdapterOptions =
  {
    server: TYPESENSE_SERVER_CONFIG,
    additionalSearchParameters: {
      // The following parameters are directly passed to Typesense's search API endpoint.
      //  So you can pass any parameters supported by the search endpoint below.
      //  queryBy is required.

      query_by: 'name,description,teaser,crs_lms,provider,crs_name,orgs',
      query_by_weights: '100,80,20,20,20,20,20',

      num_typos: 1,
      typo_tokens_threshold: 1,
      // By default, Typesense approximates facet counts for performance.
      // Turning on exhaustive search will get you accurate facet counts, at the cost of a slight performance hit.
      exhaustive_search: true,

      //exclude_fields: "f1,f2,f3",
      //include_fields: "f1,f2,f3",

      //sort_by: "provider:desc,_text_match:desc,name:asc",

      sort_by: '_text_match(buckets: 10):desc, provider:desc',

      // group_by: "provider",
      // group_limit: 2
      // pinned_hits: "23:2"
      // filter_by: "_entryStatus:=Approved"

      // filter_by: "entityType:=canvas/canvas-catalog-course && citl_status:!=Unavailable",
      // facet_query: "entityType:canvas/canvas-catalog-course",
    },
  }

export const typesenseInstantsearchAdapter = new TypesenseInstantsearchAdapter(
  TYPESENSE_INSTANCE_SEARCH_ADAPTER_CONFIG
)
