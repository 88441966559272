import { useState } from "react"
import { connectHits } from "react-instantsearch-core"
import { SearchHit } from "./SearchHit"

/**
 * Displays a collection of Search Hits
 * @param props
 * @returns
 */
function SearchHits(props: { hits: any[]; mode?: "GRID" | "LIST" | "ROW" }) {
  const { hits } = props

  const [mode, setMode] = useState(props.mode)
  const filtered = hits.filter((h) => h)
  const cards = hits.map((hit) => <SearchHit key={hit.id} hit={hit} />)

  switch (mode) {
  case "LIST":
    return (
      <div className="list-wrapper flex flex-wrap gap-4 before:content['list']">
        {" "}
        {cards}{" "}
      </div>
    )

  case "ROW":
    return (
      <div className="row-wrapper flex  flex-wrap gap-4 before:content['row']">
        {" "}
        {cards}{" "}
      </div>
    )

  case "GRID":
  default:
    return (
      <div
        className="grid-wrapper
          flex flex-col
          justify-center
          items-center
          sm:justify-center
          sm:flex-row sm:flex-wrap
          xgrid xgrid-cols-2 gap-4
          xflex xflex-col xflex-wrap xgap-4 xbefore:content['grid']
        "
      >
        {" "}
        {cards}{" "}
      </div>
    )
  }
}

export default connectHits(SearchHits)
