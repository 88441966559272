//import { debounce } from 'lodash'
import { debounce } from 'lodash-es'
import { useEffect, useState } from 'react'

// Hook
export function useWindowResize() {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/

  const [windowSize, setWindowSize] = useState<{
    width?: number
    height?: number
  }>({
    width: undefined,
    height: undefined,
  })

  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state

      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      })
    }
    // Add event listener
    window.addEventListener('resize', handleResize)
    // Call handler right away so state gets updated with initial window size
    handleResize()
    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize)
  }, []) // Empty array ensures that effect is only run on mount

  return windowSize
}

export function useDebouncedWindowResize(
  onResize?: (size: { width?: number; height?: number }) => void,
  delay = 250
) {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/

  function fetchWindowSize() {
    return {
      width: window.innerWidth,
      height: window.innerHeight,
    }
  }

  const [windowSize, setWindowSize] = useState(fetchWindowSize)

  useEffect(() => {
    // Handler to call on window resize
    const handleResizeDebounced = debounce(() => {
      // Set window width/height to state
      const size = fetchWindowSize()

      console.log('DWR resize delay', {
        time: new Date(Date.now()).toISOString().substring(11, 23),
        size,
      })

      setWindowSize(size)
      onResize && onResize(size)
    }, delay)

    // Add event listener
    window.addEventListener('resize', handleResizeDebounced)
    // Call handler right away so state gets updated with initial window size

    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResizeDebounced)
  }, []) // Empty array ensures that effect is only run on mount

  return windowSize
}

export function useDebounce<T>(value: T, delay: number) {
  const [debouncedValue, setDebouncedValue] = useState(value)

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value)
    }, delay)

    return () => {
      clearTimeout(handler)
    }
  }, [value, delay])

  return debouncedValue
}
