import {
  AnalyticsConsumer,
  AnalyticsProvider,
} from '../features/googleAnalytics/AnalyticsContext'
import { analytics } from '../features/googleAnalytics/engine'

export const SEOTestPage = () => {
  const ga = analytics()

  return (
    <AnalyticsProvider instance={ga}>
      <div>SEO Test Page</div>
      <div>
        <AnalyticsConsumer>
          {value => {
            return (
              <div>
                <div>okay1</div>

                <div>ga</div>
                <pre>{JSON.stringify(ga, null, 2)}</pre>

                <div>AnalyticsConsumer</div>
                <pre>{JSON.stringify(value, null, 2)}</pre>

                <div>Send Events</div>
                <div>
                  <button
                    onClick={() =>
                      value.viewItem([{ item_id: '1', item_name: 'product 1' }])
                    }
                  >
                    viewItem 1
                  </button>
                  <button
                    onClick={() =>
                      value.viewItem([{ item_id: '2', item_name: 'product 2' }])
                    }
                  >
                    viewItem 2
                  </button>
                </div>
              </div>
            )
          }}
        </AnalyticsConsumer>
      </div>
    </AnalyticsProvider>
  )
}
