import { useNavigate } from 'react-router-dom'
import { ISearchCardProps, SearchCard } from './SearchCard'
import './search-hit.scss'

/**
 * SearchHit Component
 * @param props
 * @returns JSX.Element
 */
export function SearchHit(props: any) {
  const { hit } = props
  const navigate = useNavigate()

  // Build SearchCard view model from hit properties
  const cardVM = createSearchCardModel(hit)

  return (
    <SearchCard
      key={hit.id}
      {...cardVM}
      onClick={() => navigate(cardVM.url)}
      // featureFlags={[<FeatureFlag key="trending"  label="TRENDING" flagType="trending" />]}
    >
      {/* <FeatureFlag label="NEW" flagType="new" />
            <FeatureFlag label="TRENDING" flagType="trending" />
            <FeatureFlag label="POPULAR" flagType="popular" /> */}
    </SearchCard>
  )
}

/**
 * Maps hit data to ISearchCardProps
 * @param hit
 * @returns ISearchCardProps
 */
function createSearchCardModel(hit: any): ISearchCardProps {
  return {
    entityId: hit.id,
    title: hit.name,
    imageUrl:
      hit.image_url ??
      '/assets/react-typesense-nd-search/blue-illinois-square.svg',
    description: LimitString(hit.teaser ?? hit.description ?? ''),

    url: `/offerings/${hit.id}`,
    externalUrl: hit.external_url,

    category: hit.object_type[0].replace('course/', ''),

    data: hit,
    features: [
      // {label:"TRENDING", type:"trending"},
      // {label:"FREE", type:"free"}
    ],
  }
}

function LimitString(value: string) {
  if (value == null || value.length <= 500) return value
  const maxChars = value.substring(0, 500)
  const trimWhitespace = maxChars.trimEnd()
  const words = trimWhitespace.split(' ')
  // remove last partial word
  words.pop()
  return `${words.join(' ')}\u2026`
}
