import classNames from "classnames"
import { ReactNode } from "react"

export interface IPropertyPairProps {
  label: ReactNode | ReactNode[],
  value: ReactNode | ReactNode[],
  labelClassName?: string | null,
  valueClassName?: string | null,
} 

export function PropertyPair(props: IPropertyPairProps & React.HTMLAttributes<HTMLDivElement>) {
  const { label, value, className } = props
  let { labelClassName, valueClassName } = props

  const wrapperClassName = classNames(className, "flex flex-row justify-between")
  labelClassName = classNames(labelClassName)
  valueClassName = classNames(valueClassName)

  return (
    <div className={wrapperClassName}>
      <div className={labelClassName}>{label}</div>
      <div className={valueClassName}>{value}</div>
    </div>
  )
}
