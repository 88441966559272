import cx from 'classnames'
import React, { Children, ReactNode, useContext, useState } from 'react'
import stringHash from 'string-hash'

export interface IProductAttribute {
  label: string
  items: ReactNode | Array<ReactNode>
}

export const AttributeSetContext = React.createContext({
  attributes: [] as IProductAttribute[],
})
//const useAttributeSetContext = useContext(AttributeSetContext)

/**
 * Displays a collection of attributes
 * @param props
 * @returns
 */
function ProductAttributeCollection(props: {
  attributes: IProductAttribute[]
  header?: ReactNode
  footer?: ReactNode
  auto?: boolean
  include?: string[] | null
  children?: ReactNode | ReactNode[]
}) {
  const { attributes, header, footer, include, children, auto = false } = props

  //const children = Children.toArray(props.children)

  //const attrMap = new Map(attributes.map(attr => [attr.label, attr]))
  //const selectedAttributes = attributes

  let selectedAttributes: IProductAttribute[] = []

  if (include == undefined) selectedAttributes = [...attributes]
  else
    selectedAttributes = attributes.filter(
      a => include.indexOf(a.label.toUpperCase()) >= 0
    )

  // const selectedAttributes = attributes.filter(a => [
  //   "CMN_TOPICS",
  //   "CRSA_TOPIC",
  //   "CMN_DIFFICULTY",
  //   "CRSA_PRODUCTDIFFICULTYLEVEL",
  //   "CMN_PROVIDER",
  //   "CMN_COST_2",
  //   "CMN_LMS",
  //   "CMN_COURSE_DURATION",
  //   "CRSA_PRODUCTDURATIONENUM",
  //   "CMN_ALL_LANGUAGES",
  //   "CMN_SCHEDULING_CONTINUOUS",
  //   "CRSA_AVGLEARNINGHOURS",
  // ].indexOf(a.label.toUpperCase()) >= 0)

  //if (auto)

  return (
    <AttributeSetContext.Provider value={{ attributes: selectedAttributes }}>
      <div className='attribute-set py-2 '>
        {header && <div className='group-header px-2'>{header}</div>}
        {auto &&
          selectedAttributes.map((attr, i) => {
            const key = stringHash(`${i}:${attr.label}`)
            return (
              attr.items != null && (
                <ProductAttribute
                  key={key}
                  label={attr.label}
                  value={attr.items}
                />
              )
            )
          })}
        {children && Children.toArray(props.children)}
        {footer && <div className='group-footer'>{footer}</div>}
      </div>
    </AttributeSetContext.Provider>
  )
}
ProductAttributeCollection.Item = ProductAttribute

export function ContextProductAttribute(props: {
  attributeId: string
  label?: string
}) {
  const { attributeId, label } = props
  const attributeContext = useContext(AttributeSetContext)
  const selectedAttribute = attributeContext.attributes.find(
    a => a.label.toUpperCase() == attributeId
  )

  const NotFound = <div className='line-through'>{attributeId}</div>

  return (
    <div>
      <>
        {selectedAttribute && (
          <ProductAttribute
            label={label ?? selectedAttribute.label ?? attributeId}
            value={selectedAttribute.items}
          />
        )}
        {selectedAttribute == null && NotFound}

        {/* <div>ATTRIBUTES: {attributeContext.attributes.length}:{attributeId}</div>
        <div>{
          attributeContext.attributes.map((a) => <div key={a.label}>{a.label}</div>)
        }</div> */}
      </>
    </div>
  )
}

/**
 * Displays a Product Attribute
 * @param props { label:string, value:ReactNode | ReactNode[] }
 * @returns JSX Node
 */
function ProductAttribute(props: {
  label: string
  value?: ReactNode | ReactNode[]
  children?: ReactNode | ReactNode[]
}) {
  const { label, value, children } = props

  const isExpanded = useState(false)
  const isLimitEntries = useState(10)

  if (value == null && children == null) return null

  const containerClassNames = 'border-t-0 text-sky-600 mb-4 text-sm'
  const labelClassNames =
    'border-b border-[#557094] px-0 mx-2 text-[#4a6a93] uppercase text-xs'
  const valuesClassNames = 'flex flex-col p0 mx-2 text-sky-600'
  const valueClassNames = ''

  const preprocessItems = () => {
    // force items to array
    const allValues = Array.isArray(value) ? [...value] : [value]
    // remove undefinded
    const filteredValues = allValues.filter(i => i !== undefined)

    // translate items (handle booleans)
    return filteredValues.map(value => translateItem(value))
  }
  const items = preprocessItems()

  const attributeValues = items.map((item, i) => {
    const key = stringHash(`${i}`)
    //return <div key={key}>{ item }<span>({typeof(item)})</span></div>
    return <AttributeValue key={key} className={valueClassNames} item={item} />
  })

  return (
    <div className={cx('attribute-set', containerClassNames)}>
      <header className={cx('attribute-set-title', labelClassNames)}>
        {label}
      </header>
      <div className={cx('attribute-set-values', valuesClassNames)}>
        {attributeValues}
        {children}
      </div>
    </div>
  )
}

function AttributeValue(
  props: { item: ReactNode | ReactNode[] } & { className?: string }
) {
  const { item, className } = props
  return (
    <div className={className}>
      {item} <span className='hidden'>({typeof item})</span>
    </div>
  )
}

function translateItem<T>(value: T, opts?: ReactNode[]): T | ReactNode {
  if (typeof value !== 'boolean') return value

  let [t, f, n] = opts ?? []
  t = t ?? 'true'
  f = f ?? 'false'
  n = n ?? 'unknown'

  if (value === null || value === undefined) return n
  if (value === true) return t
  if (value === false) return f
}

function MapBoolean<T>(props: { value: T; opts?: ReactNode[] }): T | ReactNode {
  const { value, opts } = props

  if (typeof value !== 'boolean') return value

  let [t, f, n] = opts ?? []
  t = t ?? 'true'
  f = f ?? 'false'
  n = n ?? 'unknown'

  if (value === null || value === undefined) return n
  if (value === true) return t
  if (value === false) return f
}

export {
  ProductAttribute as ProductAttribute,
  ProductAttributeCollection as ProductAttributeGroup,
}
