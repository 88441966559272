import { ReactComponent as AgriculturalSciences } from './AgriculturalSciences.svg'
import { ReactComponent as AnimalScience } from './AnimalScience.svg'
import { ReactComponent as ArtsHumanities } from './ArtsHumanities.svg'
import { ReactComponent as Business } from './Business.svg'
import { ReactComponent as ComputerScience } from './ComputerScience.svg'
import { ReactComponent as DataScience } from './DataScience.svg'
import { ReactComponent as Health } from './Health.svg'
import { ReactComponent as PersonalDevelopment } from './PersonalDevelopment.svg'
import { ReactComponent as PhysicalScienceEngineering } from './PhysicalScienceEngineering.svg'
import { ReactComponent as SocialSciences } from './SocialSciences.svg'

export const topicMap: Record<
  string,
  React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
      title?: string | undefined
    }
  >
> = {
  'Agricultural Sciences': AgriculturalSciences,
  'Animal Science': AnimalScience,
  'Arts and Humanities': ArtsHumanities,
  Business: Business,
  'Computer Science': ComputerScience,
  'Data Science': DataScience,
  Health: Health,
  'Information Technology': PersonalDevelopment,
  'Personal Development': PersonalDevelopment,
  'Physical Science and Engineering': PhysicalScienceEngineering,
  'Social Sciences': SocialSciences,
}

export {
  AgriculturalSciences,
  AnimalScience,
  ArtsHumanities,
  Business,
  ComputerScience,
  DataScience,
  Health,
  PersonalDevelopment,
  PhysicalScienceEngineering,
  SocialSciences,
}
