import { ReactNode, createContext } from 'react'
import { AnalyticsBroker, analytics } from './engine'

const createNamedContext = <T,>(name: string, initialState: T) => {
  const context = createContext(initialState)
  context.displayName = name

  return context
}

export const AnalyticsContext = createNamedContext<AnalyticsBroker>(
  'Analytics',
  analytics()
)

export const AnalyticsProvider = ({
  children,
  instance,
}: {
  children: ReactNode | ReactNode[] | null
  instance: AnalyticsBroker
}) => {
  //invariant(instance, `Analytics instance not provided to <AnalyticsProvider />`)

  return (
    <AnalyticsContext.Provider value={instance}>
      {children}
    </AnalyticsContext.Provider>
  )
}

export const AnalyticsConsumer = AnalyticsContext.Consumer
