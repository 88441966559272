import {
  ConfigurationOptions,
  NodeConfiguration,
} from 'typesense/lib/Typesense/Configuration'
import { ICourseSearchRecord } from '../../components/search-hits/models/ISearchRecord'
import ProductViewMapper from './ProductViewMapper'

interface IProductProvider {
  GetProduct<T>(id: string, collectionId?: string): Promise<T | null>
}

const DEFAULT_COLLECTION_ID = 'courses'

export class ProductProvider implements IProductProvider {
  typesenseConfig: ConfigurationOptions

  constructor(typesenseConfig: ConfigurationOptions) {
    this.typesenseConfig = {
      apiKey: typesenseConfig.apiKey,
      nodes: typesenseConfig.nodes,
    }
  }

  private baseUri = (): string => {
    const node = this.typesenseConfig.nodes[0]
    const props: Partial<NodeConfiguration> = node

    const { protocol, host, port, path, url } = props

    return url != null
      ? url
      : `${protocol ?? 'https'}://${host}:${port ?? 443}${path ?? ''}`
  }

  private baseRequest = (): RequestInit => ({
    mode: 'cors',
    headers: {
      'X-TYPESENSE-API-KEY': `${this.typesenseConfig.apiKey}`,
      Accept: 'application/json',
    },
  })

  public async GetProduct<T = ICourseSearchRecord>(
    id: string,
    collectionId = DEFAULT_COLLECTION_ID
  ) {
    type SearchResponse = { hits: Array<{ document: T }> }
    const uri = `${this.baseUri()}/collections/${collectionId}/documents/search?q=&query_by=name&filter_by=id:${encodeURIComponent(
      id
    )}`

    try {
      const response = await fetch(uri, { ...this.baseRequest() })

      // validate response
      if (!response.ok) throw new Error(`Invalid server response.`)
      const searchResponse = (await response.json()) as SearchResponse

      // validate search response
      if (searchResponse == null)
        throw new Error(`Unable to cast response to Array<{document}>.`)

      const { hits } = searchResponse

      // validate search response has hits
      if (hits == null) {
        throw new Error(`hits element is null".`)
      }

      // validate search response hits.length = 0 or 1
      const hitCount = hits.length
      if (hitCount > 1)
        throw new Error(
          `Expected element 'hits' to contain one match, found ${hitCount}.`
        )

      const hit = hits.shift() // first or undefined
      const doc = hit?.document ?? null
      return doc
    } catch (reason) {
      throw new Error(`Unable to load to product ${id}, ${reason}`)
    }
  }
}

export const SearchConverters = {
  ConvertToProductView: ProductViewMapper.ConvertToProductView,
}
