import { ReactNode } from "react"
import { PropertyPair } from "../../components/common/PropertyPair"
import { IProductView } from "../../components/course-detail/IProductView"
import { ICanvasRecord } from "../../components/search-hits/models/ICanvasRecord"
import { ICourseraRecord } from "../../components/search-hits/models/ICourseraRecord"
import { ICourseSearchRecord, ISearchRecordMetadata } from "../../components/search-hits/models/ISearchRecord"


export class Attribute {
  label:string
  items:any

  constructor(label:string, items:any) {
    this.label = label
    this.items = items
  }
}

interface IAttribute { label: string; items: ReactNode | ReactNode[] | null; }

// Converts a SearchResult<T> to an IProductView<T> 
function ConvertToProductView(source: ICourseSearchRecord): IProductView<unknown> & any {

  // Extract Core Properties
  const coreProperties = {
    id: source.id,
    name: source.name ?? "missing-name",
    teaser: source.teaser,
    imageUrl: source.image_url,
    external_url: source.external_url ?? "error",
    external_record: source,
    description: source.description ?? ""
  }

  // Extract Metadata
  const metadata: ISearchRecordMetadata = {
    _owners: source._owners,
    _posted: source._posted,
    _postedby: source._postedby,
    _record_source: source._record_source,
    _record_type: source._record_type ?? "missing-record-type",
    _status: source._status,
    _version: source._version
  }

  // Merge Extracts
  const result: IProductView<unknown> & { metadata?: ISearchRecordMetadata; } = {
    ...coreProperties,
    ...metadata,
    metadata: metadata
  }

  // Extract Attributes
  const attributes: Array<IAttribute> = []

  //COMMON
  // IProductAttributes
  {
    // search.tags  (CANVAS-, COURSERA-)
    attributes.push(new Attribute("CMN_tags", source.tags ?? [])),

    // search.objectType  (CANVAS*, COURSERA*)
    attributes.push(new Attribute("CMN_Program_Type", source.object_type))

    // search.audiences  (CANVAS-, COURSERA+)
    attributes.push(new Attribute("CMN_Audiences", source.audiences))

    // new Attribute("PRODUCT:sku", source.sku),

    // Product Category (CANVAS*, COURSERA*)
    attributes.push(new Attribute("CMN_Program_Category", [
      source["category.lvl0"], 
      source["category.lvl1"],
      source["category.lvl2"],
      source["category.lvl3"]
    ]))
  }


  // search.difficulty_enum (CANVAS-, COURSERA*)
  attributes.push(new Attribute("CMN_Difficulty", source.difficulty_enum))

  // search.provider (CANVAS*, COURSERA*)
  attributes.push(new Attribute("CMN_Provider", source.provider))

  // IPricingAttributes
  const costAttribute = new Attribute("CMN_Cost_1", null)
  if (source.price_is_free != null) {
    if (source.price_is_free.valueOf() === true) costAttribute.items = ["Free"]
  }

  if (source.price !== null) {
    //costAttibute.items.push(<PropertyPair key="price" label="Price" value={`${source.price} ${source.price_currency ?? ""}`.trimEnd()} />)
  }
  attributes.push(costAttribute)
  attributes.push(new Attribute("CMN_COST_2",
    [
      <PropertyPair key="is_free" label="Free" value={source.price_is_free?.valueOf() ? "T" : "F"} />,
      <PropertyPair key="price" label="Price" value={`${source.price} ${source.price_currency}`} />,
    ]))

  // IAcademicTopicAttributes
  attributes.push(new Attribute("CMN_Topics", [source["acad_topic.lvl0"], source["acad_topic.lvl1"]]))

  // IGeneralCourseFacets

  attributes.push(new Attribute("CMN_credit_status", source.acad_credit_status))
  attributes.push(new Attribute("CMN_credit_hrs", source.acad_credit_hrs))

  attributes.push(new Attribute("CRS_id", source.crs_id)),
  attributes.push(new Attribute("CMN_name", source.name)),

  attributes.push(new Attribute("CMN_LMS", source.crs_lms))
  attributes.push(new Attribute("CMN_Course_Type", source.crs_type))
  attributes.push(new Attribute("CMN_Course_Duration", source.duration_enum))

  //IAcademicCourseAttributes
  // new Attribute("ACAD_CRS:subj desc", source.acad_subj_desc),
  // new Attribute("ACAD_CRS:lvl0", source["acad_crs.lvl0"]),
  // new Attribute("ACAD_CRS:lvl1", source["acad_crs.lvl1"]),
  // new Attribute("ACAD_CRS:lvl2", source["acad_crs.lvl2"]),
  // IAcademicOrgAttributes

  // FROM EXTERNAL RECORD
  //attributes.push(new Attribute("Partners", (source.external_record as any).Partners))


  attributes.push(new Attribute("CMN_Organization", [
    source["org.lvl0"],
    source["org.lvl1"],
    source["org.lvl2"],
  ])),

  // new Attribute("ORG:lvl1", source["org.lvl1"]),
  // new Attribute("ORG:lvl1", source["org.lvl2"]),

  // IAcademicTermAttributes
  attributes.push(new Attribute("CMN_Academic_Terms", [
    source["acad_term.lvl0"],
    source["acad_term.lvl1"],
    source["acad_term.lvl2"]
  ]))
  attributes.push(new Attribute("CMN_Semester", source.acad_term_semester))

  // IPresentationAttributes
  attributes.push(new Attribute("CMN_Primary_language", source.language))
  attributes.push(new Attribute("CMN_All_languages", source.languages))

  attributes.push(new Attribute("CMN_Instructors", source.instructors))
  attributes.push(new Attribute("CMN_Delivery_Method", source.deliveryMethod))

  // ISchedulingAttributes
  attributes.push(new Attribute("CMN_SCHEDULING_Continuous", source.sched_continuous?.valueOf() ? "Continuous" : "Fixed Dates"))
  attributes.push(new Attribute("CMN_SCHEDULING_Start Date", [new Date(source.sched_startdate_unix ?? 0).toDateString()]))
  attributes.push(new Attribute("CMN_SCHEDULING_End Data", source.sched_enddate_unix))

  // IRatingAttributes
  attributes.push(new Attribute("CMN_RATING_avg", <span>{source.rating_avg?.toFixed(1)} ({source.rating_num})</span>))
  //new Attribute("RATING:num", source.rating_num),
  // new Attribute("RATING:adj", source.rating_adj?.toFixed(1)),
  attributes.push(new Attribute("CMN_OTHER_certificates", source.certificates))
  attributes.push(new Attribute("CMN_OTHER_citl_sectionAttributes", source.citl_sectionAttributes))
  // new Attribute("OTHER:description", source.description),
  attributes.push(new Attribute("CMN_OTHER:external_id", source.external_id))

  const customAttributes: IAttribute[] = []

  // customAttributes.push({label: "_record_type", items: source._record_type ?? "unknown"})

  switch (source._record_type ) {
  case "SearchRecord<CanvasCatalogCourse>":
    customAttributes.push(...MapCanvasAttributes(source as ICourseSearchRecord<ICanvasRecord>))
    break

  case "SearchRecord<CourseraCatalogCourse>":
    customAttributes.push(...MapCourseraCourseAttributes(source as ICourseSearchRecord<ICourseraRecord>))
    break

  default: 
    customAttributes.push({label:"DEBUG", items: <div>Unrecognized record type: {source._record_type ?? "(null)"}</div> })
    break

  }

  // Merge attributes with over-write
  const mapBaseAttributes = new Map(attributes.map(a =>[a.label.toLowerCase(), a]))
  const mapCustomAttributes = new Map(customAttributes.map(a =>[a.label.toLowerCase(), a]))
  const allAttributes = new Map([...mapBaseAttributes, ...mapCustomAttributes])

  result.attributes = [...allAttributes.values()]  //[...attributes, ...customAttributes]


  return { ...result, _PGM_SOURCE: "ProductProvider.ConvertToProductView", _DATA_SRC: source }
}

function MapCourseraCourseAttributes(source: ICourseSearchRecord<ICourseraRecord | any>) {
  const attributes:Array<IAttribute> = []
  const {external_record:coursera} = source 
  attributes.push({label: "META_Mapper", items: "ICourseraRecord"})

  // Listing Properties
  addIfDefined(attributes, "CRSA_Name", coursera?.Name)
  addIfDefined(attributes, "CRSA_ObjectId", coursera?.ObjectId)
  addIfDefined(attributes, "CRSA_ObjectUrl", coursera?.ObjectUrl)
  addIfDefined(attributes, "CRSA_ImageUrl", coursera?.ImageUrl)
  addIfDefined(attributes, "CRSA_Tagline", coursera?.Tagline)
  addIfDefined(attributes, "CRSA_Description", coursera?.Description)
  addIfDefined(attributes, "CRSA_EntityType", coursera?.EntityType)
  addIfDefined(attributes, "CRSA_EntityTypeDescription", coursera?.EntityTypeDescription)

  // Keywords
  addIfDefined(attributes, "CRSA_CourseItemNameKeywords", coursera?.CourseItemNameKeywords)
  addIfDefined(attributes, "CRSA_CourseLessonNameKeywords", coursera?.CourseLessonNameKeywords)
  addIfDefined(attributes, "CRSA_CourseModuleNameKeywords", coursera?.CourseModuleNameKeywords)

  // Categories
  addIfDefined(attributes, "CRSA_Topic", coursera?.Topic)
  addIfDefined(attributes, "CRSA_Subtopic", coursera?.Subtopic)
  addIfDefined(attributes, "CRSA_CategoryId", coursera?.CategoryId)
  addIfDefined(attributes, "CRSA_SubcategoryId", coursera?.SubcategoryId)
  addIfDefined(attributes, "CRSA_Query", coursera?.Query)

  // Ratings
  addIfDefined(attributes, "CRSA_NumProductRatings", coursera?.NumProductRatings)
  addIfDefined(attributes, "CRSA_AdjustedStarRating", coursera?.AdjustedStarRating)
  addIfDefined(attributes, "CRSA_AvgProductRating", coursera?.AvgProductRating)

  // Languages
  addIfDefined(attributes, "CRSA_Language", coursera?.Language)
  addIfDefined(attributes, "CRSA_LanguageCode", coursera?.LanguageCode)
  addIfDefined(attributes, "CRSA_AllLanguageCodes", coursera?.AllLanguageCodes)
  addIfDefined(attributes, "CRSA_AllLanguages", coursera?.AllLanguages)
  addIfDefined(attributes, "CRSA_SubtitleLanguage", coursera?.SubtitleLanguage)
  addIfDefined(attributes, "CRSA_SubtitleLanguageCode", coursera?.SubtitleLanguageCode)

  // Effort
  addIfDefined(attributes, "CRSA_AvgLearningHours", coursera?.AvgLearningHours)
  addIfDefined(attributes, "CRSA_ProductDurationEnum", coursera?.ProductDurationEnum)
  addIfDefined(attributes, "CRSA_ProductDifficultyLevel", coursera?.ProductDifficultyLevel)

  // Skilks
  addIfDefined(attributes, "CRSA_Careers", coursera?.Careers)
  addIfDefined(attributes, "CRSA_Skills", coursera?.Skills)
  addIfDefined(attributes, "CRSA_SkillAliases", coursera?.SkillAliases)
  addIfDefined(attributes, "CRSA_RawSkills", coursera?.RawSkills)
  addIfDefined(attributes, "CRSA_StableSkills", coursera?.StableSkills)

  // Relations
  addIfDefined(attributes, "CRSA_RelatedDegreeId", coursera?.RelatedDegreeId)
  addIfDefined(attributes, "CRSA_RelatedS12NId", coursera?.RelatedS12NId)

  // Partnerships
  addIfDefined(attributes, "CRSA_IsPartOfCourseraPlus", coursera?.IsPartOfCourseraPlus)
  addIfDefined(attributes, "CRSA_CobrandingEnabled", coursera?.CobrandingEnabled)
  addIfDefined(attributes, "CRSA_PartnerLogos", coursera?.PartnerLogos)
  addIfDefined(attributes, "CRSA_Partners", coursera?.Partners)

  // Enrollment
  addIfDefined(attributes, "CRSA_Enrollments", coursera?.Enrollments)
  addIfDefined(attributes, "CRSA_ActiveLast28d", coursera?.ActiveLast28d)
  addIfDefined(attributes, "CRSA_HasLabAssignments", coursera?.HasLabAssignments)

  // Price
  addIfDefined(attributes, "CRSA_IsCourseFree", coursera?.IsCourseFree)
  addIfDefined(attributes, "CRSA_HasFullDiscount", coursera?.HasFullDiscount)

  // Meta
  addIfDefined(attributes, "CRSA_IsCurrentlyLaunched", coursera?.IsCurrentlyLaunched)
  addIfDefined(attributes, "CRSA_LastUpdatedTs", coursera?.LastUpdatedTs)
  addIfDefined(attributes, "CRSA_LaunchTs", coursera?.LaunchTs)

  addIfDefined(attributes, "CRSA_OId", coursera?.OId)





  




  return attributes
}


function MapCanvasAttributes(source:ICourseSearchRecord<ICanvasRecord>): Array<Attribute> {
  const attributes:Array<Attribute> = []
  const {external_record:canvas} = source 
  attributes.push({label: "META_Mapper", items: "ICanvasRecord"})
  
  // Catalog Properties
  addIfDefined(attributes, "CVS_Canvas_Course_ID", canvas?.canvas_course?.id)
  addIfDefined(attributes, "CVS_Canvas_Catalog_ID", canvas?.catalog?.id)
  addIfDefined(attributes, "CVS_Canvas_Catalog_Name", canvas?.catalog?.name)

  // Enrollment Properties
  addIfDefined(attributes, "CVS_enrollment_open", canvas?.enrollment_open)
  addIfDefined(attributes, "CVS_enrollment_cap", canvas?.enrollment_cap)
  addIfDefined(attributes, "CVS_waitlist", canvas?.waitlist)
  addIfDefined(attributes, "CVS_waitlist_cap", canvas?.waitlist_cap)

  addIfDefined(attributes, "CVS_enrollment_fee", canvas?.enrollment_fee)
  addIfDefined(attributes, "CVS_currency", canvas?.currency)
  addIfDefined(attributes, "CVS_Allowed_Payment_Types", canvas?.allowed_payment_types)

  // Credit Properties
  addIfDefined(attributes, "CVS_credits", canvas?.credits)
  addIfDefined(attributes, "CVS_measurement", canvas?.measurement)

  // Metadata Properies
  addIfDefined(attributes, "CVS_visibility", canvas?.visibility)
  addIfDefined(attributes, "CVS_created_at", canvas?.created_at)
  addIfDefined(attributes, "CVS_updated_at", canvas?.updated_at)

  // Listing Properties
  addIfDefined(attributes, "CVS_id", canvas?.id)
  addIfDefined(attributes, "CVS_type", canvas?.type)
  addIfDefined(attributes, "CVS_title", canvas?.title)
  addIfDefined(attributes, "CVS_listing_image", canvas?.listing_image, (value) => <div><img src={value} alt={canvas?.image_alt_text ?? ""} /><span>{canvas?.image_alt_text }</span></div>)
  addIfDefined(attributes, "CVS_image_alttext", canvas?.image_alt_text)
  addIfDefined(attributes, "CVS_listing_path", canvas?.listing_path)
  addIfDefined(attributes, "CVS_listing_url", canvas?.listing_url, (value) => <a href={value}>Original Listing</a>)
  addIfDefined(attributes, "CVS_show_free_banner", canvas?.show_free_banner)

  // Tags
  addIfDefined(attributes, "CVS_tag_names", canvas?.tag_names)

 



  
  

  



  addIfDefined(attributes, "CVS_short_description", canvas?.short_description)
  addIfDefined(attributes, "CVS_sku", canvas?.sku)
  addIfDefined(attributes, "CVS_META_key", canvas?._key)
  addIfDefined(attributes, "CVS_META_serialized", canvas?._serialized)
  addIfDefined(attributes, "CVS_META_type", canvas?._type)

  return attributes
}

function doIfDefined<T>(value:T|undefined|null, action:(arg:NonNullable<T>)=> void) {
  if (value != null) action(value as NonNullable<T>)
}

function addIfDefined<T>(attributes: Attribute[], label:string, value?:T, fnItem?:(value:NonNullable<T>)=> any) {
  doIfDefined(value, v => {
    const items = (fnItem) ? fnItem(v) : v
    attributes.push( {label, items})
  })
}


export default class ProductViewMapper {
  static ConvertToProductView = ConvertToProductView
}





