/* eslint-disable react/jsx-props-no-spreading */
import 'animate.css/animate.min.css'
import { Outlet } from 'react-router-dom'
import { cssTransition, Theme, toast, ToastContainer } from 'react-toastify'
import { Footer } from './Footer'
import { Header } from './Header'

const toastDefaults = {
  position: toast.POSITION.TOP_RIGHT,
  autoClose: 3000,
  transition: cssTransition({
    enter: 'animate__animated animate__fadeInDown animate__faster',
    exit: 'animate__animated animate__fadeOut  animate__faster',
    appendPosition: false,
    collapse: false,
  }),
  hideProgressBar: true,
  newestOnTop: true,
  closeOnClick: true,
  rtl: false,
  pauseOnFocusLoss: true,
  draggable: true,
  pauseOnHover: true,
  theme: 'dark' as Theme,
}

export const PageLayout = () => {
  return (
    <div className='search-app-page-template search-app-page-template-default'>
      <Header display={false} />
      <Outlet />
      <Footer />
      <ToastContainer {...toastDefaults} />
    </div>
  )
}
