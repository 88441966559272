import { useEffect, useState } from 'react'
import { Pagination } from 'react-instantsearch-dom'
import { useDebouncedWindowResize } from '../../hooks/useWindowResize'

/** breakpoint map for pagination buttons */
const breakpointMapping = [
  { minWidth: 0, padding: 1 },
  { minWidth: 420, padding: 2 },
  { minWidth: 480, padding: 3 },
  { minWidth: 540, padding: 4 },
  { minWidth: 640, padding: 5 },
  { minWidth: 768, padding: 6 },
]

/**
 * COMPONENT: WidthAwarePagination
 * Wraps an instant-search Pagination component to dynamically modify
 * the padding property when the window size changes.
 * @param {*} props
 * @returns
 */
export default function WidthAwarePagination(props: any) {
  const windowSize = useDebouncedWindowResize()

  // number page button to show to the left and right of the current page
  const [padding, setPadding] = useState(1)

  const calculatePadding = (width: number) => {
    const breakpointSettings = lookupBreakpointRecord(
      breakpointMapping,
      width ?? 0
    )
    return breakpointSettings?.padding ?? 1
  }

  // when size changes, recalculate padding
  useEffect(() => {
    // lookup breakpoint settings for this value
    const calcPadding = calculatePadding(windowSize.width) ?? 1
    // get padding for window size
    setPadding(calcPadding)
    // console.log(`getPadding ${size.width}`, calcPadding)
  }, [windowSize])

  // eslint-disable-next-line react/jsx-props-no-spreading
  return <Pagination {...props} padding={padding} />
}

/**
 * Util: get value associated with container-width by returning the last entry
 * that meets a given condition
 *
 * Assumes breakpointMapping array is sorted by minWidth
 */
function lookupBreakpointRecord<T extends { minWidth: number }>(
  breakpointMap: T[],
  containerWidth: number
) {
  const value = findLast(breakpointMap, b => b.minWidth <= containerWidth)
  return value
}

/** Util: get last matching element in an array */
const findLast = <T,>(
  arr: T[],
  fnCheck: (item: T) => boolean
): T | undefined => {
  let match: T | undefined = undefined
  match = arr.reduce(
    (prev, item) => (fnCheck(item) ? item : prev),
    undefined as T | undefined
  )
  return match
}
