import qs from 'qs'

import { useMediaQuery } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { Popover, Whisper } from 'rsuite'
// import 'rsuite/styles/index.less'
import 'rsuite/Popover/styles/index.less' //index.less'
import './searchPage.scss'

// import './App.css'

// import styled from "styled-components";

import {
  ClearRefinements,
  Configure,
  CurrentRefinements,
  InstantSearch,
  Panel,
  RefinementList,
  SearchBox,
  Stats,
} from 'react-instantsearch-dom'

import TypesenseInstantSearchAdapter from 'typesense-instantsearch-adapter'
import SearchHits from '../components/search-hits/SearchHits'
import { TYPESENSE_INSTANCE_SEARCH_ADAPTER_CONFIG } from '../config/typesense'
import { Maps, Translate } from '../data/maps'

import { ErrorBoundary } from '../components/common/ErrorBoundary'
import WidthAwarePagination from '../components/search/WidthAwarePagination'
import { CustomSearchError } from '../features/course-search/SearchError'

import { SVGAttributes } from 'react'

import RefinementButtonList from '../components/search/RefinementButtonList'

export function QuestionMarkCircleIcon(props: SVGAttributes<SVGElement>) {
  return (
    <svg
      fill='currentColor'
      viewBox='0 0 20 20'
      xmlns='http://www.w3.org/2000/svg'
      aria-hidden='true'
      {...props}
    >
      <path
        clipRule='evenodd'
        fillRule='evenodd'
        d='M18 10a8 8 0 11-16 0 8 8 0 0116 0zM8.94 6.94a.75.75 0 11-1.061-1.061 3 3 0 112.871 5.026v.345a.75.75 0 01-1.5 0v-.5c0-.72.57-1.172 1.081-1.287A1.5 1.5 0 108.94 6.94zM10 15a1 1 0 100-2 1 1 0 000 2z'
      />
    </svg>
  )
}

// function App() {
//   const [count, setCount] = useState(0)

//   return (
//     <div className="App">
//       <div>
//         <a href="https://vitejs.dev" target="_blank">
//           <img src="/vite.svg" className="logo" alt="Vite logo" />
//         </a>
//         <a href="https://reactjs.org" target="_blank">
//           <img src={reactLogo} className="logo react" alt="React logo" />
//         </a>
//       </div>
//       <h1>Vite + React</h1>
//       <div className="card">
//         <button onClick={() => setCount((count) => count + 1)}>
//           count is {count}
//         </button>
//         <p>
//           Edit <code>src/App.tsx</code> and save to test HMR
//         </p>
//       </div>
//       <p className="read-the-docs">
//         Click on the Vite and React logos to learn more
//       </p>
//     </div>
//   )
// }

/*
const TYPESENSE_SERVER_CONFIG: ConfigurationOptions = {
  apiKey: "A2jglLpZIzyHCTbGRfRdLtQtCa69DV2E" , // Be sure to use an API key that only allows searches, in production
  nodes: [
    {
      host: "nmi0zea2kyc9jrpbp-1.a1.typesense.net",
      port: 443,
      protocol: "https",
    },
  ],
  connectionTimeoutSeconds: 1,
  numRetries: 8,
  cacheSearchResultsForSeconds: 2 * 60,
  //logLevel?: LogLevelDesc;
  //logger?: Logger;
};
*/

/*
const typesenseInstantsearchAdapter = new TypesenseInstantSearchAdapter({
  server: TYPESENSE_SERVER_CONFIG,
  additionalSearchParameters: {
    // The following parameters are directly passed to Typesense's search API endpoint.
    //  So you can pass any parameters supported by the search endpoint below.
    //  queryBy is required.

    query_by: 'name,description,teaser,citl_subject,citl_lmsPlatforms',
    query_by_weights: '100,20,20,10,10',

    num_typos: 1,
    typo_tokens_threshold: 1,
    // By default, Typesense approximates facet counts for performance.
    // Turning on exhaustive search will get you accurate facet counts, at the cost of a slight performance hit.
    exhaustive_search: true,

    // group_by: "categories",
    // group_limit: 1
    // pinned_hits: "23:2"
  },
});
*/

const typesenseInstantsearchAdapter = new TypesenseInstantSearchAdapter(
  TYPESENSE_INSTANCE_SEARCH_ADAPTER_CONFIG
)
const { searchClient } = typesenseInstantsearchAdapter

/*
// const search = instantsearch({
//   searchClient,
//   indexName: 'courses',
//   routing: true,
// });

// // ============ Begin Widget Configuration
// search.addWidgets([
//   searchBox({
//     container: '#searchbox',
//     showSubmit: false,
//     showReset: false,
//     placeholder: 'Search for products... ',
//     autofocus: false,
//     cssClasses: {
//       input: 'form-control form-control-sm border border-light text-dark',
//       loadingIcon: 'stroke-primary',
//     },
//   }),

//   pagination({
//     container: '#pagination',
//     cssClasses: {
//       list: 'd-flex flex-row justify-content-end',
//       item: 'px-2 d-block',
//       link: 'text-decoration-none',
//       disabledItem: 'text-muted',
//       selectedItem: 'fw-bold text-primary',
//     },
//   }),
*/

/*
  refinementList({
    limit: 10,
    showMoreLimit: 50,
    container: '#brand-list',
    attribute: 'entity_type',
    searchable: true,
    searchablePlaceholder: 'Search brands',
    showMore: true,
    sortBy: ['name:asc', 'count:desc'],
    cssClasses: {
      searchableInput:
        'form-control form-control-sm form-control-secondary mb-2 border-light-2',
      searchableSubmit: 'd-none',
      searchableReset: 'd-none',
      showMore: 'btn btn-secondary btn-sm',
      list: 'list-unstyled',
      count: 'badge text-dark-2 ms-2',
      label: 'd-flex align-items-center',
      checkbox: 'me-2',
    },
  }),
  */

/*
  hierarchicalMenu({
    container: '#categories-hierarchical-menu',
    showParentLevel: true,
    //rootPath: 'Cell Phones',
    attributes: [
      'categories.lvl0',
      'categories.lvl1',
      'categories.lvl2',
      'categories.lvl3',
    ],
    cssClasses: {
      showMore: 'btn btn-secondary btn-sm',
      list: 'list-unstyled',
      childList: 'ms-4',
      count: 'badge text-dark-2 ms-2',
      link: 'text-dark text-decoration-none',
      selectedItem: 'text-primary fw-bold',
      parentItem: 'text-dark fw-bold',
    },
  }),
  */

/*
  toggleRefinement({
    container: '#toggle-refinement',
    attribute: 'free_shipping',
    templates: {
      labelText: 'Free shipping',
    },
    cssClasses: {
      label: 'd-flex align-items-center',
      checkbox: 'me-2',
    },
  }),
  */
/*
  rangeSlider({
    container: '#price-range-slider',
    attribute: 'price',
  }),
  */
/*
  ratingMenu({
    container: '#rating-menu',
    attribute: 'rating',
    cssClasses: {
      list: 'list-unstyled',
      link: 'text-decoration-none',
      starIcon: '',
      count: 'badge text-dark-2 ms-2',
      disabledItem: 'text-muted',
      selectedItem: 'fw-bold text-primary',
    },
  }),
  */

// sortBy({
//   container: '#sort-by',
//   items: [
//     { label: 'Relevancy', value: 'courses' },
//     { label: 'Price (asc)', value: 'courses/sort/name:asc' },
//     { label: 'Price (desc)', value: 'courses/sort/name:desc' },
//   ],
//   cssClasses: {
//     select: 'form-select form-select-sm border-none text-black',
//   },
// }),
// hits({
//   container: '#hits',
//   templates: {
//     //item(data) { return <div>hello1</div>}
//     item(data) { return "<div>hello1</div>"}
//     /*
//     item: `
//       <div className="border-rose-900 border">
//         <div>MATCH
//           <div class="row image-container">
//               <div class="col-md d-flex align-items-end justify-content-center">
//                   <img src="{{imageUrl}}" alt="{{name}}" />
//               </div>
//           </div>
//           <div class="row mt-5">
//               <div class="col-md">
//                   <h5>{{#helpers.highlight}}{ "attribute": "name" }{{/helpers.highlight}}</h5>
//               </div>
//           </div>
//           <div class="row mt-2">
//               <div class="col-md">
//                 <div>TEASER</div>
//                 {{#helpers.highlight}}{ "attribute": "teaser" }{{/helpers.highlight}}
//               </div>
//           </div>
//           <div class="row mt-auto">
//             <div class="col-md">

//               <div class="hit-price fw-bold mt-4">PRICE: \${{price}}</div>
//               <div class="hit-rating">RATIN: {{rating}}/5</div>
//             </div>
//           </div>
//       </div>
//     `,
//     */
//   },
// cssClasses: {
//   list: 'list-unstyled grid-container',
//   item: 'd-flex flex-column search-result-card mb-1 me-1 p-3',
//   //loadMore: 'btn btn-primary mx-auto d-block mt-4',
//   //disabledLoadMore: 'btn btn-dark mx-auto d-block mt-4',
// },
// }),
// hitsPerPage({
//   container: '#hits-per-page',
//   items: [
//     { label: '9 per page', value: 9, default: true },
//     { label: '18 per page', value: 18 },
//   ],
//   cssClasses: {
//     select: 'form-select form-select-sm border-none text-black',
//   },
// }),
//   stats({
//     container: '#stats',
//     templates: {
//       text: `
//       {{#hasNoResults}}No products{{/hasNoResults}}
//       {{#hasOneResult}}1 product{{/hasOneResult}}
//       {{#hasManyResults}}{{#helpers.formatNumber}}{{nbHits}}{{/helpers.formatNumber}} products{{/hasManyResults}}
//       found in {{processingTimeMS}}ms
//     `,
//     },
//     cssClasses: {
//       text: 'small',
//     },
//   }),
//   clearRefinements({
//     container: '#clear-refinements',
//     cssClasses: {
//       button: 'btn btn-primary',
//     },
//   }),
// ]);

// (window as any).sendEventDebounced = debounce((uiState) => {
//   window.gtag('event', 'page_view', {
//     page_path: window.location.pathname + window.location.search,
//   });
// }, 500);

// search.use(() => ({
//   onStateChange({ uiState }) {
//     (window as any).sendEventDebounced(uiState);
//   },
//   subscribe() {},
//   unsubscribe() {},
// }));

// search.start();

/* Create http querystring from SearchState */
const createURL = (state: any) => `?${qs.stringify(state)}`

/* Convert SearchState to URL */
const searchStateToUrl = (location: any, searchState: any) => {
  const url = searchState ? `${location.pathname}${createURL(searchState)}` : ''
  // console.log(`converted searchStateToUrl: ${url}`)
  return url
}

/* Convert URL to SearchState */
const urlToSearchState = (location: { search: string }) => {
  const searchState = qs.parse(location.search.slice(1))
  // console.log(`converted location to searchState: ${JSON.stringify(searchState)}`, searchState)
  return searchState
}

/**
 * Search Page Component
 * @returns
 */
function SearchPage() {
  const location = useLocation()
  const navigate = useNavigate()

  const setStateId = React.useRef<number | undefined>()
  const [searchState, setSearchState] = React.useState(
    urlToSearchState(location)
  )

  // When page location changes => Update search state
  React.useEffect(() => {
    const nextSearchState = urlToSearchState(location)
    if (JSON.stringify(searchState) !== JSON.stringify(nextSearchState)) {
      setSearchState(nextSearchState)
    }
  }, [location])

  // On mount, set document title
  React.useEffect(() => {
    document.title = 'CITL Non Credit Course Search'
  }, [])

  // const [searchParams, setSearchParams] = useSearchParams();
  // const q = searchParams.get("q")

  // Handle SearchStateChange
  const onSearchStateChange = (nextSearchState: any) => {
    const DEBOUNCE_TIMEOUT = 500
    // cancel pending nav history updates
    clearTimeout(setStateId.current)
    // queue update to nav history
    setStateId.current = window.setTimeout(() => {
      const nextUrl = searchStateToUrl(location, nextSearchState)
      navigate(nextUrl, {
        state: nextSearchState,
        replace: false,
        preventScrollReset: true,
        relative: 'path',
      })
    }, DEBOUNCE_TIMEOUT)
    setSearchState(nextSearchState)
  }

  // const entityTypeLabelMap = new Map([
  //   ['COURSE','Coursera Courses'],
  //   ['CANVAS/CANVAS-CATALOG-COURSE','Canvas Courses'],
  //   ['TEST/TEST-DOCUMENT','Custom Offerings'],
  // ])

  const mapFacetEntityTypes = (items: { label: string }[]) =>
    items.map(item => ({
      ...item,
      label: Translate(item.label, Maps.entityTypeLabels) ?? item.label,
    }))

  const searchConfig = {
    searchIndexName: 'courses',
    hitsPerPage: 24,
  }

  return (
    <ErrorBoundary>
      <InstantSearch
        indexName={searchConfig.searchIndexName}
        searchClient={searchClient}
        stalledSearchDelay={500}
        searchState={searchState}
        onSearchStateChange={onSearchStateChange}
        createURL={createURL}
        // routing = {{ stateMapping: simple()}}
      >
        <Configure
          hitsPerPage={searchConfig.hitsPerPage}
          distinct
          //filters={["_entryStatus:Approved"]}
          // AND NOT citl_status:Unavailable"
        />
        <div className='citl-search-page mx-auto w-full min-w-[280px] max-w-5xl flex-grow flex-col'>
          <div className='search-page-layout'>
            <div className='xflex mb-4 hidden justify-between border-b-2'>
              <div>Search</div>
            </div>

            {/* SearchBox */}
            <div className='searchbox-container flex items-center gap-2 p-2 '>
              <div className='text-sm'>Find Courses</div>
              <SearchBox className='flex-1' />
            </div>

            <div className=' my-2 border-t-2 '>
              <div className='hidden text-sm'>Topics</div>
              <div>Online and Professional Courses</div>
              <div>
                <div>Explore a topic</div>

                {/* Topic refinements */}
                <div>
                  <RefinementButtonList
                    attribute='acad_topic.lvl0'
                    showMore={true}
                  ></RefinementButtonList>
                </div>
              </div>
            </div>

            <div className='active-refinement-container my-2 border-t-2 '>
              <div className='p-4 pb-0'>
                <div className='text-xs'>Refinements</div>
              </div>

              {/* Current refinements */}
              <div className='flex flex-wrap gap-6 p-4 pt-0'>
                <ClearRefinements
                  className='h-1'
                  translations={{
                    reset: 'Clear',
                  }}
                />
                <CurrentRefinements
                  transformItems={transformCurrentRefinements}
                />
              </div>

              {/* Refinement selectors */}
              <RefinementsListOptions className='refinement-selectors-panel' />

              {/* 
                <Panel header="Resource Type" footer={<div />}>
                  <RefinementList
                    attribute="entityType"
                    transformItems={mapFacetEntityTypes}
                  />
                </Panel> 
              */}

              {/* 
                <Panel header="Area of Study" footer={<div></div>} >
                  <RefinementList attribute="citl_subject"/>
                </Panel>

                <Panel header="Partners" footer={<div></div>} >
                  <RefinementList attribute="citl_organizations"/>
                </Panel> */}

              {/* 
                <Panel header="Secondary Languages" footer={<div />}>
                  <RefinementList attribute="languages"/>
                </Panel> 
              */}

              {/* 
                <Panel header="Subtopic" footer={<div />}>
                  <RefinementList attribute="subtopic" />
                </Panel> 
              */}
            </div>

            <div className='results-container my-2'>
              {/* Status */}
              <div className='mb-4 flex justify-between border-b-2'>
                <div>Search Results</div>
                <Stats
                  translations={{
                    stats(
                      nbHits,
                      _processingTimeMS,
                      _nbSortedHits,
                      _areHitsSorted
                    ) {
                      return `${nbHits.toLocaleString()} ${
                        nbHits == 1 ? 'match' : 'matches'
                      }`
                    },
                  }}
                />
              </div>
              <div className='flex flex-wrap justify-end p-4'>
                <CustomSearchError />
              </div>

              {/* <Breadcrumb attributes={['entityType']}/> */}
              <SearchHits />

              <div className='flex flex-col justify-center py-4 '>
                <WidthAwarePagination className='mx-auto' />
                {/* <Pagination className="" padding={2}/> */}
              </div>
            </div>
          </div>
        </div>
      </InstantSearch>
    </ErrorBoundary>
  )
}

const RefinementsListOptions = (props: { className?: string }) => {
  const { className } = props
  const [isOpen, setIsOpen] = useState(true)

  //const { items, refine } = useRefinementList({ attribute: 'acad_topic.lvl0' })

  const [isPhoneOnly, isMobile, isTablet, isLaptop, isDesktop] = useMediaQuery([
    '(max-width: 480px)',
    '(min-width: 640px)',
    '(min-width: 768px)',
    '(min-width: 1024px)',
    '(min-width: 1280px)',
  ])

  const [refinementLimit, setRefinementLimit] = useState(4 as number | null)
  const [showMore, setShowMore] = useState(true as boolean | undefined)

  useEffect(() => {
    setRefinementLimit(isTablet ? 16 : 4)
    setShowMore(true)
  }, [isPhoneOnly, isMobile, isTablet, isLaptop, isDesktop])

  return (
    <div className={'refinement-selectors-panel' + className}>
      <div className='mb-4 flex justify-between border-b-2'>
        <div className=''>
          <span>Filter Options</span>
        </div>
        <div>
          <button
            className='text-sm'
            onClickCapture={e => {
              e.preventDefault()
              setIsOpen(!isOpen)
            }}
          >
            [{!isOpen ? 'Show' : 'Hide'}]
          </button>
        </div>
      </div>

      <div
        className='refinement-selectors flex flex-wrap gap-6 px-4'
        style={{ display: isOpen ? 'flex' : 'none' }}
      >
        {/*  TEST PANEL 
        <Panel
          header='Topic Data'
          className='min-w-[288px] grow'
          footer={<div />}
        >
          <ConnectedRefinementList attribute='acad_topic.lvl0' limit={20} />
        </Panel>
        */}

        {/* 
          Facet Filter: "Topic" 
          Typesense attribute: "acad_topic.lvl0"  
        */}
        <Panel header='Topic' className='min-w-[288px] grow' footer={<div />}>
          <RefinementList
            attribute='acad_topic.lvl0'
            collapsible={{ collapsed: true }}
            limit={refinementLimit}
            showMore={showMore}
          />
        </Panel>

        {/* 
          Facet Filter: "Topic" 
          Typesense attribute:"language"  
        */}
        <Panel
          header={<div>Primary Language</div>}
          className='min-w-[144px]  grow'
          footer={<div />}
        >
          <RefinementList
            attribute='language'
            defaultRefinement={['English']}
            limit={refinementLimit}
            showMore={showMore}
          />
        </Panel>

        {/* 
          Facet Filter: "Duration" 
          Typesense attribute:"duration_enum"  
        */}
        <Panel
          collapse={() => true}
          header='Duration'
          className='min-w-[144px] grow'
          footer={<div />}
        >
          <RefinementList
            attribute='duration_enum'
            limit={refinementLimit}
            showMore={showMore}
          />
        </Panel>

        {/* 
          Facet Filter: "Platform" 
          Typesense attribute:"crs_lms"  
        */}
        <Panel
          className='min-w-[144px]  grow'
          header={
            <div>
              <Whisper
                placement='top'
                trigger='click'
                speaker={
                  <Popover style={{ maxWidth: '45ch' }}>
                    <h1 className='font-bold'>Platforms</h1>
                    Illinois Online partners with a variety of online learning
                    platforms to host learning experiences.{' '}
                    <Link to='/info'>
                      Learn more about online course platforms
                    </Link>
                    .
                  </Popover>
                }
              >
                <div
                  style={{
                    textTransform: 'inherit',
                    display: 'flex',
                    width: '100%',
                  }}
                >
                  <span>Platform</span>
                  <QuestionMarkCircleIcon
                    style={{
                      marginLeft: 'auto',
                      display: 'inline',
                      verticalAlign: 'text-bottom',
                      height: '14px',
                      color: 'var(--color-primary-action)',
                    }}
                  />
                </div>
              </Whisper>
            </div>
          }
          footer={<div />}
        >
          <RefinementList
            attribute='crs_lms'
            transformItems={(items: any) =>
              items.map((item: { label: string }) => ({
                ...item,
                label: Translate(item.label, Maps.lmsPlatformLabels),
              }))
            }
            limit={refinementLimit}
            showMore={showMore}
          />
        </Panel>
      </div>
    </div>
  )
}

// translate labels values
const RefinementsTranslationMap = new Map<string, string>([
  ['acad_topic.lvl0', 'Topic'],
  ['language', 'Language'],
  ['duration_enum', 'Duration'],
  ['crs_lms', 'LMS'],
])

/**
 * Converts refinement state to a display state
 * @param items an array of serach refinements
 * @returns
 */
function transformCurrentRefinements(
  items?: { attribute: string; label: string }[],
  translationMap?: Map<string, string>
) {
  const translations = translationMap || RefinementsTranslationMap
  if (items == null) return items

  // remove duplicates
  const uniqueItems = [...new Map(items.map(i => [i.attribute, i])).values()]

  // translate values
  const translatedItems = uniqueItems.map(item => {
    const translatedItem = {
      ...item,
      label: translations.has(item.attribute)
        ? translations.get(item.attribute) + ': '
        : item.label,
    }
    return translatedItem
  })

  return translatedItems
}

export default SearchPage
