import classNames from 'classnames'
import Color from 'color'
import React, { ReactNode } from 'react'
import { ActionButtonSet } from '../common/ActionButtonSet'
import { ColorizedBackgroundImage } from './helpers/multilayer-background'

const defaultHeaderBackgroundStyle = {
  backgroundRepeat: 'no-repeat,no-repeat,no-repeat',
  backgroundPosition: 'center,center,center',
  backgroundSize: 'cover',
  // backgroundBlendMode: "screen"
}

const generateBackgroundStyle2 = (image: string, color: Color) => {
  const baseColor = Color(color)

  const colors = {
    base: [baseColor, baseColor],
    saturate: [Color('black'), Color('black')], // gray scale
    multiply: [baseColor.alpha(1), baseColor.alpha(1)],
    screen: [baseColor.alpha(0.1), baseColor.alpha(1)],
  }

  const createLinearGradient = ([c1, c2]: Array<Color>) =>
    `linear-gradient(${c1.rgb().toString()},${c2.rgb().toString()})`

  const layers: Array<React.CSSProperties> = [
    {
      backgroundImage: createLinearGradient(colors.screen),
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      backgroundBlendMode: 'screen',
    },

    {
      backgroundImage: createLinearGradient(colors.multiply),
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      backgroundBlendMode: 'multiply',
    },

    {
      backgroundImage: createLinearGradient(colors.saturate),
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      backgroundBlendMode: 'saturation',
    },

    {
      backgroundImage: `url(${image})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      backgroundBlendMode: 'normal',
    },

    {
      backgroundImage: createLinearGradient(colors.base),
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      backgroundBlendMode: 'normal',
    },
  ]

  const styles = {
    backgroundSize: layers.map(layer => layer.backgroundSize).join(', '),
    backgroundPosition: layers
      .map(layer => layer.backgroundPosition)
      .join(', '),
    backgroundImage: layers.map(layer => layer.backgroundImage).join(', '),
    backgroundBlendMode: layers
      .map(layer => layer.backgroundBlendMode)
      .join(', ')
      .toString(),
  }

  return styles
}

const generateBackgroundStyle = (image: string, color: Color) => {
  const builder = new ColorizedBackgroundImage(image, color)
  return builder.toCss()
}

export const headerBackgroundStyle = (
  color1: string,
  color2: string | null,
  image?: string
) => ({
  ...defaultHeaderBackgroundStyle,

  // backgroundImage: `linear-gradient(${color1},${color1}),linear-gradient(${color2},${color2}),url('${image}')`
  // backgroundImage: `linear-gradient(${color1},${color1}),url('${image}`
  ...(color1 && { backgroundImage: `linear-gradient(${color1},${color1})` }),
  ...(image && { backgroundImage: `url('${image}')` }),
  // backgroundColor: `${color1}`
})

export interface ICourseBannerProps {
  title: string | ReactNode
  imageUrl?: string
  categoryName?: string | ReactNode
  actions?: any
}

/**
 * @param props {ICourseBannerProps} Component Properties
 * @return A react component displaying a course banner
 *
 * @usage hello
 */
export function CourseBanner(
  props: ICourseBannerProps & React.HTMLAttributes<HTMLElement>
) {
  const { imageUrl, categoryName, actions, title, className, ...htmlAttr } =
    props

  // const headerStyle = headerBackgroundStyle("red", "blue", imageUrl)

  const backgroundStyles = generateBackgroundStyle(
    imageUrl ?? '',
    Color([30, 64, 175])
  )
  // console.log("backgroundStyles", backgroundStyles)

  return (
    <header
      className={classNames(
        'course-banner',
        'relative backdrop-blur-sm',
        className
      )}
      style={
        {
          //...headerStyle,
          // backdropFilter: "blur(3px) grayscale(100%) hue-rotate(30deg) brightness(.5);"
        }
      }
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...htmlAttr}
    >
      <div className='bg2-overlay absolute top-0 right-0 hidden h-full w-full bg-blue-800 mix-blend-screen' />

      <div
        className={`bgImage
                xbg-blue-800 xmix-blend-screen
                 relative p-3 
                sm:p-6 
        `}
        style={{
          ...backgroundStyles,
        }}
        // style={{
        //   //backgroundImage: `linear-gradient(rgb(30,64,175),rgb(30,64,175)), url(${imageUrl})`,
        //   backgroundSize: "cover, cover, cover, cover",
        //   backgroundPosition: "center, center, center, center",
        //   backgroundBlendMode: "screen, multiply, saturation, normal",
        //   backgroundImage: `
        //     linear-gradient(rgba(30,64,175,0.1), rgba(30,64,175,1)),
        //     linear-gradient(rgba(30,64,175,1), rgba(30,64,175,1)),
        //     linear-gradient(black,black),
        //     url(${imageUrl})`,
        //   // xgrayscale(100%) xbrightness(.5) xhue-rotate(30deg)",
        //   //...headerStyle
        // }}
      >
        <div style={{ backdropFilter: 'blur(2px)' }}>
          <div
            className='bgImage-inner-container 
            
            mx-auto flex max-w-screen-lg items-stretch gap-4 px-0'
          >
            <div
              id='header-col-1'
              className='course-header-col-1
              xcol-span-2 flex w-16 flex-shrink-0 flex-col justify-start 
              sm:w-32 sm:justify-center
              md:w-36
              lg:w-48'
            >
              <img
                className='banner-image
                aspect-square border-2 border-white object-cover 
                sm:border-4'
                src={imageUrl}
                alt=''
              />
            </div>

            <div
              id='header-col-2'
              className='course-header-col-2
              flex flex-grow flex-col
              items-start gap-y-0'
            >
              <div
                id='flag-row'
                className='
                order-1 flex 
                sm:order-1 '
              >
                <div className='strong p-0 text-sm text-white'>
                  {categoryName}
                </div>
              </div>

              <div
                id='text-row'
                className='title 
                order-2
                flex
                flex-grow-0 flex-col justify-center
                text-xl
                leading-6  text-white
                sm:text-3xl'
              >
                {title}
              </div>

              <nav
                id='action-bar'
                className='action-bar 
                order-3
                mt-auto
                hidden flex-grow-0 flex-row gap-2 pt-4 align-bottom sm:flex'
              >
                {actions && <ActionButtonSet actions={actions} />}
              </nav>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div>
          <nav
            id='action-bar-2'
            className='action-bar-mobile 
                order-3
                mt-auto
                flex
                flex-grow-0
                flex-row justify-end gap-x-2 border-t border-t-[navy] bg-[#7b93cd] px-4 py-2 align-middle
                sm:hidden'
          >
            {actions && <ActionButtonSet actions={actions} />}
          </nav>
        </div>
      </div>
    </header>
  )
}
