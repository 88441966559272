import { FaCircleCheck } from "react-icons/fa6";
import * as TopicIcons from '../../assets/topic-icons';
import './TopicButtons.scss';

export type RefinementNode = {count: number; isRefined: boolean; label: string; value: string[] }

export const TopicButton = ({item, isActive, hasItems, onClick }:{item:RefinementNode, isActive:boolean, hasItems:boolean,} & React.HTMLAttributes<HTMLButtonElement>) => {
  const IconComponent =  TopicIcons.topicMap[item.label] ?? TopicIcons.AgriculturalSciences


  const styles = {
    default:{
      "--tb-icon-background-color":"gold", 
      "--tb-icon-primary-color":"red",
      fill:"red"
    } as (React.CSSProperties & Record<`--${string}`, string|number|boolean>),
    none:{}
  }


  return (
    <button onClick={(e)=>onClick && onClick(e)} 
      className={`topic-button ${isActive ? `selected` : `not-selected`}
        h-full w-full
        flex flex-col justify-stretch items-stretch relative
        rounded-md
        min-w-[150px]
        min-h-[95px]
    `}>

      {/* icon layer */}
      <div className='icon-layer absolute top-0 left-0 bottom-[2px] right-[2px] flex items-stretch rounded-md'
        style={styles.none} 
      >
        <IconComponent className={`mx-auto rounded-md ${isActive ? `icon icon-active` : `icon`}`}/>
      </div>

      {/* text layer */}
      <div className="text-layer mr-[2px] mb-[2px] p-4 z-50 flex flex-col grow justify-center align-center rounded-md">
        {/* <div className="text-xs text-white p-2 min-h-[1em]">{isActive ? 'SELECTED' : ''}</div> */}
        <div style={{textShadow:"1px 2px 2px #000", fontSize:"16px", lineHeight:1.2}} className="block text-white font-bold">{item.label}</div>
        {/* <div className="text-xs text-white p-2">{item.count} courses</div> */}
        {/* <div className="xblock text-xs hidden">{JSON.stringify(item.value)}</div> */}
      </div>

      {/* disabled layer */}
      { item.count == 0 && <div className='absolute right-0 bottom-0 left-0 top-0 hidden' >disabled</div> }
  
      {/* count layer */}
      <div className='absolute right-0 bottom-0 px-1 hidden'>{item.count}</div>

      {/* selection status layer */}
      <div className='absolute left-0 top-0 px-1 hidden'>{item.isRefined ? <span >
        <FaCircleCheck
          size="24px" 
          stroke="red" 
          fill="navy"
        />
      </span> : 'F'}</div>

    
    </button>
  )
}