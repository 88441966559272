import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { PulseLoader } from 'react-spinners'
import { CourseDetail } from '../components/course-detail/CourseDetail'
import { IProductView } from '../components/course-detail/IProductView'
import { ICourseSearchRecord } from '../components/search-hits/models/ISearchRecord'
import { TYPESENSE_SERVER_CONFIG } from '../config/typesense'
import {
  ProductProvider,
  SearchConverters,
} from '../services/products/ProductProvider'

// function sleep(ms: number) {
//   return new Promise(resolve => setTimeout(resolve, ms))
// }

// async function sleepAsync(ms: number) {
//   await new Promise(resolve => setTimeout(resolve, ms))
// }

/**
 * ReactPage: Acquires and Displays a Course (Section) Detail Page
 */
export function DetailPage() {
  // Get productId from ReactRouter route path parameter ("id").
  const { id: productId } = useParams()

  // Component State
  const [product, setProduct] = useState(null as ICourseSearchRecord | null)
  const [productView, setProductView] = useState(
    null as IProductView<unknown> | null
  )
  const [loadingState, setLoadingState] = useState({
    id: 'LOADING',
    msg: `Loading product ${productId}`,
  })

  // onChange(productId)
  // Fetch ProductViewModel associated with requested product id when id changes
  useEffect(() => {
    // Load Course Record from Database
    const fetchCourseSearchRecord = async (productId: string | undefined) => {
      // validate arguments
      if (productId == undefined) {
        setProduct(null)
        setProductView(null)
        setLoadingState({
          id: 'ERROR',
          msg: `Missing required argument 'productId'.`,
        })
        // TODO: Log validation error
        return
      }

      // Fetch course search record for productId
      // initialize product provider
      const products = new ProductProvider(TYPESENSE_SERVER_CONFIG)

      // fetch product record
      setLoadingState({
        id: 'LOADING',
        msg: `Fetching product '${productId}.'`,
      })
      const searchRecord = await products.GetProduct<ICourseSearchRecord>(
        productId
      )

      // validate product record result
      if (searchRecord == null) {
        // TODO: Log fetch error
        // handle null searchResult
        setProduct(null)
        setProductView(null)
        setLoadingState({
          id: 'ERROR',
          msg: `ProductId "${productId}" was not found in the catalog.`,
        })
        return
      } else {
        setProduct(searchRecord ?? null)
        setLoadingState({
          id: 'LOADING',
          msg: `Fetched product '${productId}'`,
        })
        // TODO: Log fetch success
      }

      // create product view
      // construct ProductViewModel from CourseSearchRecord
      const productView =
        searchRecord != null
          ? SearchConverters.ConvertToProductView(searchRecord)
          : null

      if (productView == null) {
        // TODO: Log conversion error
        // handle null productView
        setLoadingState({
          id: 'ERROR',
          msg: `Error Creating product view '${productId}'`,
        })
      } else {
        // handle valid productView
        setLoadingState({
          id: 'LOADED',
          msg: `Created product view '${productId}'`,
        })
        setProductView(productView)
        // TODO: Log conversion success
      }

      products
        .GetProduct<ICourseSearchRecord>(productId)
        .then(async searchRecord => {
          // handle GetProductResult results
          //setLoadingState('LOADING')

          setProduct(searchRecord ?? null)

          // console.log(
          //   `DetailPage.tsx searchRecord (state.Product ${productId})`,
          //   searchRecord
          // )

          //setLoadingState('LOADING 2')
          //await sleep(2000)

          //TODO: Should use Helmet and apply SEO Properties
          document.title = `CITL Non Credit Course: '${
            searchRecord?.name ?? productId
          }' (${searchRecord?.crs_lms && searchRecord?.crs_lms[0]})`
          console.log(
            `DetailPage.tsx productView (state.ProductView) ${productId}`,
            productView
          )

          // update ProductView
          //Promise.resolve().then(async () => {
          // console.log(
          //   `COMP useEffect[productId] (${renderCount}): setLoadingState('LOADING 2A')`
          // )
          //setLoadingState('LOADING 2A')
          // await sleep(2000)

          // console.log(
          //   `COMP useEffect[productId] (${renderCount}): setProductView() 2`
          // )
          setProductView(productView)
          setLoadingState({
            id: 'LOADED',
            msg: `${productId}:${productView.name}`,
          })

          // console.log(
          //   `COMP useEffect[productId] (${renderCount}): setLoadingState('LOADING 3')`
          // )
          //setLoadingState('LOADING 3')
        })
      //})
      //})
    } // end fetchCourseSearchRecord

    // Call FetchData
    // document.title = `CITL Non Credit Course: '${productId}'`
    if (productId == null) {
      setProduct(null)
      setProductView(null)
      setLoadingState({ id: 'ERROR', msg: `MISSING_PRODUCT_ID` })
    } else {
      fetchCourseSearchRecord(productId).catch(console.error)
    }
  }, [productId])

  // onComponentMount()
  useEffect(() => {
    //trigger layout
    window.dispatchEvent(new Event('resize'))
  }, [])

  // console.log(`COMP compRenderCount`, {
  //   productId,
  //   product,
  //   productView,
  //   loadingState,
  // })

  // render CourseDetailView
  return (
    <div
      className={`search-app-detail-page status-${loadingState.id.toLowerCase()} x-min-h-screen`}
    >
      {/* case section state: LOADING */}
      {loadingState.id == 'LOADING' && (
        <StatusPanel msg='LOADING'>
          <PulseLoader color='#7499ce' loading margin={4} size={10} />
          Loading course {productId}...
        </StatusPanel>
      )}

      {/* case section state: LOADED */}
      {loadingState.id == 'LOADED' &&
        (productView ? (
          <CourseDetail product={productView} />
        ) : (
          <ErrorPanel msg='ERROR'>
            Error: Course &apos;{productId}&apos; not found.
          </ErrorPanel>
        ))}

      {/* case section state: ERROR */}
      {loadingState.id == 'ERROR' && (
        <ErrorPanel msg='ERROR'>
          Course &apos;{productId}&apos; not found
        </ErrorPanel>
      )}
    </div>
  )
}

/**
 * ErrorPanel
 * @param props
 * @returns JSX Component
 */
const ErrorPanel: React.FC<{
  msg?: string
  children?: React.ReactNode | React.ReactNode[]
}> = props => {
  const { msg, children } = props

  return (
    <div className='my-2 mx-auto max-w-[960px] rounded-lg border border-red-800 bg-red-100 p-4 py-2'>
      <div>{msg ?? 'ERROR'}</div>
      {children && <div>{children}</div>}
    </div>
  )
}

/**
 * StatusPanel
 * @param props
 * @returns JSX Component
 */
const StatusPanel: React.FC<{
  msg?: string
  children?: React.ReactNode | React.ReactNode[]
}> = props => {
  const { msg = 'STATUS', children } = props

  return (
    <div className='my-2 mx-auto max-w-[960px] rounded-lg border border-green-800 p-4 py-2'>
      <div>{msg}</div>
      {children && <div>{children}</div>}
    </div>
  )
}
