import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from 'react-router-dom'
import 'react-toastify/dist/ReactToastify.css'
import './app.scss'
import './index.css'
import { DetailPage } from './pages/DetailPage'
import InfoPage from './pages/InfoPage'
import Page404 from './pages/Page404'
import { SEOTestPage } from './pages/SEOTestPage'
import SearchPage from './pages/SearchPage'
import { PageLayout } from './pages/shared/PageLayout'

/*<Router basename={import.meta.env.VITE_PUBLIC_URL}> */
export default function App(props: any) {
  return (
    <Router basename='/catalog/non-credit'>
      {/* <Router> */}
      <Routes>
        <Route element={<PageLayout />}>
          <Route index path='/' element={<Navigate to='/search' replace />} />
          <Route index path='/search' element={<SearchPage />} />
          <Route index path='/info' element={<InfoPage />} />
          <Route index path='/offerings/:id' element={<DetailPage />} />
          <Route index path='/error-404/:msg?' element={<Page404 />} />
          <Route index path='/seoTest' element={<SEOTestPage />} />
          <Route index path='*' element={<Page404 />} />
        </Route>
      </Routes>
    </Router>
  )
}
