/* eslint-disable indent */
import classNames from 'classnames'
import { useEffect } from 'react'
import { Link } from 'react-router-dom'
import sanitizeHtml from 'sanitize-html'
import {
  IProductAttribute,
  ProductAttributeGroup,
} from './../common/AttributeGroup'
import { PropertyPair } from './../common/PropertyPair'
import { ICanvasRecord } from './../search-hits/models/ICanvasRecord'
import { ICourseSearchRecord } from './../search-hits/models/ISearchRecord'
import { CourseBanner } from './CourseBanner'
import { CourseDetailLabels } from './CourseDetail'
import { IProductView } from './IProductView'
import { toCurrencyString } from './helpers/formatters'

/**
 * Maps a Search Result Document to a CourseDetail ProductView
 * @param product A SearchResult Document
 * @returns
 */
export function CreateCourseDetailViewModel(
  product: ICourseSearchRecord
): IProductView<ICanvasRecord> {
  const { external_record } = product
  const externalRecord = external_record as ICanvasRecord

  const viewModel: IProductView<ICanvasRecord> = {
    id: product.id,
    imageUrl: product.image_url,
    name: product.name ?? '{name is missing}',
    entityTypeDescription: product.object_type && product.object_type[0],

    // eslint-disable-next-line no-underscore-dangle
    _entryStatus: product._status,
    allLanguages: product.languages,
    citl_audiences: product.audiences,
    'citl_category.lvl1': product['category.lvl0'],
    'citl_category.lvl2': product['category.lvl1'],

    citl_creditStatus: product.acad_credit_status,
    citl_crs_type: product.crs_type,
    citl_deliveryMethod: product.deliveryMethod,

    citl_status: product._status,
    description: product.description,
    //collectionNames: product.collectionNames,
    citl_lmsPlatforms: product.crs_lms,
    productType: product.object_type,
    externalProps: { objUrl: product.external_url },

    externalRecord: externalRecord,

    actions: {
      enroll: {
        title: 'Register',
        text: 'Register',
        url: externalRecord.listing_url,
      },
    },

    textSections: {
      description: {
        title: 'Description',
        text: externalRecord.description,
      },
    },

    attributes: [
      {
        label: 'Category',
        items: [
          'Course',
          'Canvas Course',
          ...(product.acad_credit_status ??
            'ERR: product.acad_credit_status missing'),
          ...(product.crs_type ?? 'ERR: product.crs_type missing'),
        ],
      },

      // {label: "Title", items: course.title?.toString()},
      // {label: "Short Description", items: course.short_description?.toString()},
      {
        label: 'Open for Enrollment',
        items: externalRecord.enrollment_open?.toString(),
      },

      { label: 'Credits', items: externalRecord?.credits ?? 'none' },
      // {label: "Measurement", items: course.measurement?.toString()},
      // {label: "Enrollment", items: [
      //     <PropertyPair key="1" label="Capacity" value={product.externalRecord?.enrollment_cap} />,
      //     <PropertyPair key="2" label="Waitlist Available" value={BooleanToText(product.externalRecord?.waitlist)} />,
      //     <PropertyPair key="3" label="Waitlist Size" value={product.externalRecord?.waitlist_cap} />,
      // ]},
      // {label: "Enrollment Capacity", items: [course.enrollment_cap?.toString()]},
      // {label: "Waitlist Available", items: course.waitlist?.toString()},
      // {label: "Waitlist Capacity", items: course.waitlist_cap?.toString()},
      {
        label: 'Enrollment Fee',
        items: [
          externalRecord.enrollment_fee != null
            ? `${Number.parseFloat(externalRecord.enrollment_fee ?? '').toFixed(
                2
              )} ${externalRecord.currency}`
            : null,
          // course.allowed_payment_types
        ],
      },
      // {label: "Allowed Payment Types", items: course.allowed_payment_types },
      { label: 'Tags', items: externalRecord?.tag_names },

      {
        label: 'Enroll',
        items: <a href={externalRecord?.listing_url}>View in Canvas Catalog</a>,
      },

      // {label: "Listing Image", items: <img className="max-h-72px p-2" src={course.listing_image} alt={course.image_alt_text} />},
      // {label: "image_alt_text", items: course.image_alt_text?.toString()},
      // {label: "Listing Path", items: course.listing_path?.toString()},
      // {label: "show_free_banner", items: course.show_free_banner?.toString()},
      {
        label: 'Canvas Catalog',
        items: `${externalRecord.catalog?.name} (${externalRecord?.catalog?.id})`,
      },
      // {label: "Canvas Course ID", items: course.canvas_course.id },
      {
        label: 'Metadata',
        items: [
          <PropertyPair
            key='1'
            label='Visibility'
            value={externalRecord?.visibility?.toString()}
          />,
          <PropertyPair
            key='2'
            label='Created'
            value={new Date(
              Date.parse(externalRecord?.created_at)
            ).toLocaleDateString()}
          />,
          <PropertyPair
            key='3'
            label='Updated'
            value={new Date(externalRecord.updated_at).toLocaleDateString()}
          />,
        ],
      },
    ] as Array<{ label: string; items: any }>,

    // unmapped properties
    teaser: null,
    external_url: '',

    _postedby: '',
    _record_source: '',
    _status: '',
    _record_type: '',
    _owners: [],
    _posted: 0,
    _version: '0',
    _record_status: '',
    object_type: '',
    sku: '',
    'category.lvl0': '',
    'acad_topic.lvl0': product['acad_topic.lvl0'] ?? undefined,
    'acad_topic.lvl1': product['acad_topic.lvl0'] ?? undefined,
    language: '',
    languages: '',
    acad_credit_status: '',
    crs_lms: '',
    crs_type: '',
    duration_enum: '',
    external_record: undefined,
    collectionNames: undefined,
  }
  return viewModel
}

/**
 * Displays course details for a Canvas course
 * @param props
 * @returns
 */
export function GenericCourseDetail(props: {
  product: IProductView<ICanvasRecord>
}) {
  useEffect(() => {
    // trigger layout refresh
    const recalcLayout = (window as any).recalcLayout
    if (recalcLayout != null) {
      recalcLayout()
    }
  })

  const { product } = props

  const model = product // CreateCourseDetailViewModel(product)

  console.log('product', product)
  console.log('model', model)

  const bannerProps = {
    title: model.name,
    imageUrl: model.imageUrl,
    categoryName: model.entityTypeDescription,
    actions: model.actions,
  }

  // Convert Attribute to Maps, divide by section.
  const attrMap = new Map<string, unknown>(
    model.attributes
      ?.filter((attr: any) => attr)
      .map((attr: any) => [attr.label.toLowerCase(), attr])
  )
  const attrMapCommon = new Map(
    [...attrMap.entries()].filter(([key]) => key.startsWith('cmn'))
  )
  const attrMapCanvas = new Map(
    [...attrMap.entries()].filter(([key]) => key.startsWith('cvs'))
  )
  const attrMapMeta = new Map(
    [...attrMap.entries()].filter(([key]) => key.startsWith('meta'))
  )

  console.log('attrMapCommon', attrMapCommon)
  console.log('attrMapCanvas', attrMapCanvas)
  console.log('attrMapMeta', attrMapMeta)
  console.log('attrMap', attrMap)

  const attrContainerClassName = `
    attributes-container
    order-2
    min-w-[240px] 
    flex-shrink
    border-2 border-gray-400 bg-gray-100 rounded-lg
    sm:max-w-[260px]`

  model.actions = {
    ...model.actions,
    enroll: {
      title: 'Enroll Now',
      text: 'Register',
      url: product.external_url,
      className: 'text-[navy]',
    },
  }

  function searchFilterLink(
    facetId: string,
    facetValue: string,
    label?: string
  ) {
    const queryKey = encodeURIComponent(`refinementList[${facetId}][0]`)
    const queryValue = encodeURIComponent(facetValue)
    const url = `/search?${queryKey}=${queryValue}`
    return <Link to={url}>{label ?? facetValue}</Link>
  }

  return (
    <div
      className='course-detail course-detail-canvas'
      data-course-id={model.id}
    >
      {/** Course Detail Banner */}
      <CourseBanner
        title={model.name}
        imageUrl={
          model.imageUrl ??
          '/assets/react-typesense-nd-search/blue-illinois-square.svg'
        }
        categoryName={model.entityTypeDescription}
        actions={model.actions}
      />

      {/** Course Detail Body */}
      <div className='body mx-auto max-w-screen-lg '>
        {/** Course Detail Section: Main */}
        <div
          className='
                    flex flex-col flex-wrap gap-x-4 p-4
                    sm:flex-row sm:flex-nowrap sm:items-start'
        >
          {/* Attributes Container */}
          <div
            className='
                order-2
                flex
                min-w-[240px] flex-col
                gap-y-4 '
          >
            {/* PRINCIPAL PROPERTIES */}
            <div className={attrContainerClassName}>
              {attrMapCommon.size && (
                <ProductAttributeGroup attributes={[]}>
                  <ProductAttributeGroup.Item
                    label='Topic'
                    value={
                      (attrMapCommon.get('cmn_topics') as any)?.items?.map(
                        (topic: string) =>
                          searchFilterLink('acad_topic.lvl0', topic)
                      ) ?? <div className='text-gray-400'>[unindexed]</div>
                    }
                  />

                  {/* <Link key={topic} to={`/search?${encodeURIComponent('refinementList[acad_topic.lvl0][0]')}=${encodeURIComponent(topic)}`}>{topic}</Link>)) */}
                  {/* <ProductAttributeGroup.Item label="Item" value={attrMapCommon.get("cmn_program_type")?.items} /> */}
                  {/* <ProductAttributeGroup.Item label="Subtopic" value={attrMapCommon.get("cmn_subtopic")?.items} /> */}

                  <ProductAttributeGroup.Item
                    label='Platform'
                    value={
                      (attrMapCommon.get('cmn_lms') as any)?.items?.map(
                        (topic: string) => searchFilterLink('crs_lms', topic)
                      ) ?? <div className='text-gray-400'>[unindexed]</div>
                    }
                  />

                  {/* <ProductAttributeGroup.Item label="Platform" value={attrMapCommon.get("cmn_provider")?.items ?? "Canvas"} /> */}
                  {/* <ProductAttributeGroup.Item label="Categories" value={attrMapCommon.get("cmn_program_category")?.items} /> */}
                </ProductAttributeGroup>
              )}

              {/* CANVAS CREDIT PROPERTIES */}
              {/* {attrMap.get("cvs_measurement") && (
              <div className={attrContainerClassName}>
                { attrMap.get("cvs_measurement")?.items &&
                  <ProductAttributeGroup attributes={[]} header="Credits">
                    <ProductAttributeGroup.Item label="Measurement" value={attrMap.get("cvs_measurement")?.items} />
                    <ProductAttributeGroup.Item label="Units" value={attrMap.get("cvs_credits")?.items} />
                  </ProductAttributeGroup>
                }
              </div>)
            } */}

              {/* CANVAS ENROLLMENT PROPERTIES */}
              {/* <div className={attrContainerClassName}> */}
              <ProductAttributeGroup attributes={[]}>
                <ProductAttributeGroup.Item
                  label='Status'
                  value={
                    (attrMap.get('cvs_enrollment_open') as any)?.items == true
                      ? 'available'
                      : 'pending'
                  }
                />
                {/* <ProductAttributeGroup.Item label="Is Open" value={JSON.stringify(attrMap.get("cvs_enrollment_open")?.items)} /> */}
                {/* <ProductAttributeGroup.Item label="Has Waitlist" value={attrMap.get("cvs_waitlist")?.items} /> */}
                <ProductAttributeGroup.Item
                  label='Cost'
                  value={toCurrencyString(
                    (attrMap.get('cvs_enrollment_fee') as any)?.items,
                    0
                  )}
                />
                {/* <ProductAttributeGroup.Item label="Currrency" value={attrMap.get("cvs_currency")?.items} /> */}
                {/* <ProductAttributeGroup.Item label="Payment Types" value={attrMap.get("cvs_allowed_payment_types")?.items} /> */}
              </ProductAttributeGroup>
              {/* </div> */}

              {/* CANVAS CATALOG PROPERTIES */}
              {/* <div className={attrContainerClassName}>
              <ProductAttributeGroup attributes={[]} header="Canvas Catalog">
                <ProductAttributeGroup.Item label="Canvas Course ID" value={attrMap.get("cvs_id")?.items} />
                <ProductAttributeGroup.Item label="Catalog Name" value={attrMap.get("cvs_canvas_catalog_name")?.items} />
                <ProductAttributeGroup.Item label="Catalog ID" value={attrMap.get("cvs_canvas_catalog_id")?.items} />
                <ProductAttributeGroup.Item label="Catalog Entry ID">{attrMap.get("cvs_canvas_course_id")?.items}</ProductAttributeGroup.Item>
                <ProductAttributeGroup.Item label="Entry Type" value={attrMap.get("cvs_type")?.items} />
                <ProductAttributeGroup.Item label="Visibility" value={attrMap.get("cvs_visibility")?.items} />
              </ProductAttributeGroup>
            </div> */}

              {/* TAGS PROPERTIES */}
              {/* {attrMapCommon.size && (
              <div className={attrContainerClassName}>
                <ProductAttributeGroup attributes={[]} header="Tags">
                  <ProductAttributeGroup.Item label="Tags" value={attrMapCommon.get("cmn_tags")?.items} />
                </ProductAttributeGroup>
              </div>
            )} */}

              {/* ALL CANVAS PROPERTIES */}
              {attrMapCanvas.size && (
                <div className={classNames(attrContainerClassName, 'hidden')}>
                  <ProductAttributeGroup
                    attributes={[
                      ...(attrMapCanvas.values() as unknown as Array<IProductAttribute>),
                    ]}
                    header='All Canvas Properties'
                  />
                </div>
              )}

              {/* ALL COMMON PROPERTIES */}
              {attrMapCommon.size && (
                <div className={classNames(attrContainerClassName, 'hidden')}>
                  <ProductAttributeGroup
                    attributes={[
                      ...(attrMapCommon.values() as unknown as Array<IProductAttribute>),
                    ]}
                    header='All Common Properties'
                  />
                </div>
              )}

              {model.attributes && (
                <div className={classNames(attrContainerClassName, 'hidden')}>
                  <div>All Attributes</div>
                  <ProductAttributeGroup attributes={model.attributes} />
                </div>
              )}
            </div>
          </div>

          {/* Main Column */}
          <div className='main-body order-1 shrink grow'>
            <header className='p-2'>
              <div className='hidden text-xs uppercase'>
                CANVAS COURSE DETAIL
              </div>
              <h3 className='text-3xl'>{model.name}</h3>
            </header>

            {(model.description == null ||
              model.description.indexOf(model.teaser) < 1) && (
              <div className='section-teaser p-2'>
                <h2 className='text-2xl'>Summary</h2>
                <div className='p-2'>{model.teaser}</div>
              </div>
            )}

            {/* <pre className="hidden text-xs break-all">M:{JSON.stringify(model, null, 2)}</pre> */}

            <div className='section-description'>
              <h2 className='p-2 text-2xl'>Description</h2>
              <div
                className='p-2 '
                dangerouslySetInnerHTML={{
                  __html: sanitizeHtml(model.description, {
                    allowedTags: sanitizeHtml.defaults.allowedTags.concat([
                      'iframe',
                      'hr',
                      'img',
                    ]),
                    allowedAttributes: {
                      ...sanitizeHtml.defaults.allowedAttributes,
                      iframe: [
                        'src',
                        'class',
                        'style',
                        'width',
                        'height',
                        'frameborder',
                      ],
                    },
                  }),
                }}
              />
            </div>
            {/* 
            <div className="section-description p-2">
              <h3 className="text-xl">Description</h3>
              <div className="section-description-raw-html" dangerouslySetInnerHTML={{__html: courseGroup.description}}/>
            </div> 
          */}

            {/* Conditionally render TextSections */}
            {model.textSections &&
              Object.keys(model.textSections).map(key => {
                const section = (model.textSections as any)[key]
                return (
                  <div key={key} className={` section-${key} p-2`}>
                    <h3 className='py-2 text-xl'>{section.title}</h3>
                    <div
                      className='section-description-raw-html'
                      dangerouslySetInnerHTML={{ __html: section.text }}
                    />
                  </div>
                )
              })}

            {/* Action Buttons */}
            <div
              className='cta-container
                align-content-center my-4 flex flex-col items-center gap-2
                md:flex-row md:flex-wrap md:justify-center md:justify-items-stretch'
            >
              <a
                className='register-link citl-btn citl-btn-register 
                  min-w-[240px] max-w-[240px]
                  md:min-w-[120px]'
                href={model.external_url}
              >
                {CourseDetailLabels.RegisterButtonLabel}
              </a>
              {/* <a
                className="learn-more-link citl-btn citl-btn-secondary 
                  min-w-[240px] max-w-[240px] 
                  md:min-w-[120px]"
                href={model.externalProps?.objUrl}
              >
                {CourseDetailLabels.LearnMoreButtonLabel}
              </a> */}
              <Link
                className='search-link citl-btn citl-btn-secondary 
                  min-w-[240px] max-w-[240px]
                  md:min-w-[120px] '
                to='/search'
              >
                {CourseDetailLabels.SearchButtonLabel}
              </Link>
            </div>

            <div></div>

            {/*
            {
                (() => {
                    const sectionRecord = courseGroup.textSections as Record<string,{title?: string, index?: string, text:string}>
                    return <div>{sectionRecord.requirements.title} Records: {sectionRecord.requirements.text}</div>
                })()
            }

            {
    
                Object.entries(courseGroup.textSections)
                    .filter(([_key, value]) => value as {text: string} != null)
                    .map(([key, value]) => {
                        return (
                            <div key={key} className={`section-${key} p-2 text-red-800`}>
                            <h3 className="text-xl">{value.title}</h3>
                            {value.text}
                        </div>
                        )
                    })
            }
          */}

            {/* 
            <div className="section-instructors border-t-2 pb-6">
                <header className="p-2"><h3 className="text-xl">Instructors</h3></header>
                <div className="p-2">
                {
                    courseGroup.instructors.map(instructor =>
                        <div key={stringHash(instructor.name)}>
                            { instructor.name }
                        </div>)
                }
                </div>
            </div> 
          */}

            {/* 
            <div className="overflow-x-scroll">
                <h4>sampleData</h4>
                <div>{JSON.stringify(canvasCatalogCourses)}</div>
            </div> 
          */}
          </div>
        </div>

        {/* 
          <div className="section-related-items flex flex-col border-t-2 pb-6">
            <header className="p-2"><h3 className="text-xl">RELATED COURSES (to {activeCourseId})</h3></header>
            { activeCourseId && <RelatedCourses
                recommendClient = {recommendClient!}
                searchIndex={algoliaProductsIndex}
                objectID={activeCourseId}
                />
            }
          </div> 
        */}
        {/*
            <div className="section-related-items flex flex-col border-t-2 pb-6">
              <header className="p-2"><h3 className="text-xl">You might also like</h3></header>
              <div className="item-slider p-2 flex flex-row flex-nowrap fl gap-2">
                {
                  courseGroup.relatedItems.map(item =>
                    <div
                        key={stringHash(item.title)}
                        className="h-48 w-40 border-4">
                    {item.title}
                    </div>
                  )
                }
              </div>
            </div>
        */}

        {/* 
          <div className="section-promoted-items flex flex-col border-t-2 pb-6">
            <header className="p-2"><h3 className="text-xl">Promoted Items</h3></header>
            <div className="item-slider p-2 flex flex-row flex-nowrap fl gap-2">
              {
                courseGroup.promotedItems.map(item =>
                <div
                  key={stringHash(item.title)}
                  className="h-48 w-40 border-4">
                  {item.title}
                </div>)
              }
            </div>
          </div> 
        */}

        {/* 
          <div className="section-sharing flex flex-col border-t-2 pb-6">
                <header className="p-2">
                    <h3 className="text-xl">Sharing</h3>
                </header>
                <p className="text-center font-bold font-size-lg py-6">
                 Know someone who would like this course?<br />
                 Share it with them!
                </p>
                <div className="item-slider p-2 flex flex-row flex-nowrap fl gap-2 justify-center">
                    <div className="border-blue-600 border-2 rounded-lg px-4 py-2"><a href="#">Twitter</a></div>
                    <div className="border-blue-600 border-2 rounded-lg px-4 py-2"><a href="#">Facebook</a></div>
                    <div className="border-blue-600 border-2 rounded-lg px-4 py-2"><a href="#">Linked In</a></div>
                </div>
            </div> 
        */}
      </div>
      {/** Course Detail Footer */}
      <footer></footer>
    </div>
  )
}

function ProductCta(props: { model: any }) {
  const { model } = props

  const containerClassNames = `
    flex flex-col items-center align-content-center gap-2 my-4
    md:flex-row md:flex-wrap md:justify-center md:justify-items-stretch`

  const btnCommonClassNames = (variant: string) => `
    citl-btn
    citl-btn-${variant} 
    min-w-[240px] max-w-[240px]
    md:min-w-[120px]
  `

  return (
    <div className={classNames('cta-container', containerClassNames)}>
      <a
        className={classNames('register-link', btnCommonClassNames('primary'))}
        href={model.externalProps?.objUrl}
      >
        {CourseDetailLabels.RegisterButtonLabel}
      </a>
      <a
        className={classNames(
          'learn-more-link',
          btnCommonClassNames('secondary')
        )}
        href={model.externalProps?.objUrl}
      >
        {CourseDetailLabels.LearnMoreButtonLabel}
      </a>
      <Link
        className={classNames('search-link', btnCommonClassNames('secondary'))}
        to='/search'
      >
        {CourseDetailLabels.SearchButtonLabel}
      </Link>
    </div>
  )
}
