import { StatusCircleIcon } from '../../components/common/StatusIcon'

/**
 * App Header React Component
 * @desc Displays appTitle and appStatus
 */
export const Header = (props: {
  display?: boolean
  appTitle?: string
  appStatus?: string
}) => {
  const {
    appTitle = 'CITL Non Credit Course Search',
    appStatus = 'unknown',
    display = true,
  } = props

  if (!display) return <></>

  return (
    <div
      className='app-header 
        mx-auto flex w-full max-w-6xl flex-row py-2'
      style={{ display: 'hidden' }}
    >
      <div
        className='app-header-title
          flex min-h-[3em] items-center py-2 px-2'
      >
        {appTitle}
      </div>
      <div
        className='app-header-status
          ml-auto flex items-center px-2'
      >
        <StatusIndicator status={appStatus} />
      </div>
    </div>
  )
}

/**
 * Visual Status Indicator Component
 * @param param0
 * @returns
 */
export const StatusIndicator = ({
  status,
  cssStyle,
}: {
  status: string
  cssStyle?: React.CSSProperties
}) => {
  // default palette
  const colors = {
    gray100: '#A3A3A340',
    green600: '#15803D40',
    red600: '#B91C1C40',
    amber100: '#D9770640',
    stroke: '#55555580',
  }

  // default attributes
  const defaultAttributes = {
    state: 'loading',
    bgFill: colors.gray100,
    bgStroke: colors.stroke,
    cssStyle: { width: '16px', height: '16px' },
  }

  // merged options
  let attributes = {
    ...defaultAttributes,
    style: { ...defaultAttributes.cssStyle, cssStyle },
  }

  // set status
  switch (status) {
    case 'error':
      attributes = {
        ...attributes,
        bgFill: colors.red600,
        bgStroke: colors.red600,
      }
      break
    case 'success':
    case 'ready':
      attributes = {
        ...attributes,
        bgFill: colors.green600,
        bgStroke: colors.green600,
      }
      break
    case 'loading':
      attributes = {
        ...attributes,
        bgFill: colors.gray100, //amber100,
        bgStroke: colors.stroke,
      }
      break
    case 'unknown':
      attributes = {
        ...attributes,
        bgFill: colors.gray100,
      }
      break
    default:
      attributes = {
        ...attributes,
        bgFill: '#18181B',
      }
      break
  }

  return (
    <div className='flex items-center gap-1 px-1'>
      <StatusCircleIcon
        state={status}
        bgFill={attributes.bgFill}
        bgStroke={attributes.bgStroke}
        style={attributes.cssStyle}
        label={status && `status: ${status}`}
      />
      <span className='hidden text-xs'>{status}</span>
    </div>
  )
}
