import { useMemo } from 'react'
import type { RefinementListExposed, RefinementListProvided } from 'react-instantsearch-core'
import {
  connectRefinementList
} from 'react-instantsearch-dom'
import { TopicButton } from './TopicButton'


export type RefinementButtonListProps = RefinementListProvided & RefinementListExposed
export type RefinementNode = {count: number; isRefined: boolean; label: string; value: string[] }

const defaultKeys = [
  "Agricultural Science",
  "Animal Science",
  "Arts and Humanities",
  "Business",
  "Computer Science",
  "Data Science",
  "Health",
  "Information Technology",
  "Personal Development",
  "Physical Science and Engineering",
  "Social Sciences",
] 

const createNode = (key:string, currentRefinement:Array<string>) => ({
  label:key, 
  value: currentRefinement.includes(key)? currentRefinement.filter(s=>s===key) : [...currentRefinement, key],
  count:-1, 
  isRefined:false
})

const mapKeyToNodeRecord = (labels:Array<string>, currentRefinement: Array<string>) => 
  labels.reduce((prev, key) => 
    ({...prev, [key]: createNode(key, currentRefinement)}), 
    {} as Record<string, RefinementNode>
  )

const mapItemsToNodeRecord = (items:Array<RefinementNode>) => 
  items.reduce((prev, item) => ({...prev, [item.label]: item}), {} as Record<string, RefinementNode>)

const mergeRecords = <T,>(a:Record<string,T>, b:Record<string,T>):Record<string,T> => ({...a, ...b})

const transformItems = (defaultKeys:Array<string>, items:Array<RefinementNode>, currentRefinement:Array<string>) => {
  const defaultMap = mapKeyToNodeRecord(defaultKeys,currentRefinement)
  const itemMap = useMemo(() => mapItemsToNodeRecord(items), [items]) 
  const mergedMap = mergeRecords(defaultMap, itemMap)
  const sortedItems = Object.values(mergedMap).sort((a,b)=> a===b ? 0: (a.label < b.label ? -1: 1))
  return sortedItems
} 

const RefinementButtonList = (props: RefinementButtonListProps) => {

  const {attribute, items, currentRefinement, refine} = props

  const resultItems = transformItems(defaultKeys, items, currentRefinement)

  return (
    <>
      {/* <div>attribute: {attribute}</div>
      <div>items: {items?.length}</div>
      <div>items(ref): {items?.filter(i => i.isRefined)?.length}</div>
      <div>currentRefinements: {currentRefinement?.length}</div> */}
      {/* <RefinementList attribute={attribute} limit={limit} showMore={true} /> */}
      <style> {`

        :root {

          --theme-color-blue: hsl(216.4,59.6%,18.4%);
          --theme-color-sky: hsl(195,100%,41.6%); 

          --theme-color-orange: hsl(21.6,100%,51%);
          --theme-color-brick: hsl(0,100%,50%); 

          --theme-tb-icon-background-color: var(--theme-color-blue, #13294b);
          --theme-tb-icon-primary-color: var(--theme-color-sky, #009FD4);
          --theme-tb-icon-secondary-color: var(--theme-color-blue, #13294b);

          --theme-tb-icon-hover-background-color: hsl(216.4,59.6%,18.4%);  /* #13294b; */
          --theme-tb-icon-hover-primary-color: hsl(195,100%,41.6%); /* #009FD4; */
          --theme-tb-icon-hover-secondary-color: hsl(216.4,59.6%,18.4%);   /* #13294b; */

          --theme-tb-icon-selected-background-color: var(--theme-color-orange, #ff5f05) ;
          --theme-tb-icon-selected-primary-color: var(--theme-color-brick, red);
          --theme-tb-icon-selected-secondary-color: var(--theme-color-orange, #ff5f05);

          --theme-tb-icon-selected-hover-background-color: hsl(21.6, 100%, 51%); /* #ff5f05; */
          --theme-tb-icon-selected-hover-primary-color: hsl(0, 100%, 50%); /* red; */
          --theme-tb-icon-selected-hover-secondary-color: hsl(21.6, 100%, 51%); 

        }

        .xtopic-button {
          --tb-icon-background-color: var(--theme-tb-icon-background-color);
          --tb-icon-primary-color: var(--theme-tb-icon-primary-color);
          --tb-icon-secondary-color: var(--theme-tb-icon-secondary-color);
        }

        .xtopic-button:hover {
          --tb-icon-background-color: var(--theme-tb-icon-hover-background-color);
          --tb-icon-primary-color: var(--theme-tb-icon-hover-primary-color);
          --tb-icon-secondary-color: var(--theme-tb-icon-hover-secondary-color);
        }

 
        .xtopic-button.selected {
          --tb-icon-background-color:var(--theme-tb-icon-selected-background-color);
          --tb-icon-primary-color:var(--theme-tb-icon-selected-primary-color);
          --tb-icon-secondary-color:var(--theme-tb-icon-selected-secondary-color);
        }

    
        .xtopic-button.selected:hover {
          --tb-icon-background-color: var(--theme-tb-icon-selected-hover-background-color);
          --tb-icon-primary-color: var(--theme-tb-icon-selected-hover-primary-color);
          --tb-icon-secondary-color: var(--theme-tb-icon-selected-hover-secondary-color);
        }

        .xtopic-button .icon-layer {
          box-shadow:1px 2px 2px #000;
          background-color: var(--tb-icon-background-color);
        }

        .xtopic-button:hover .icon-layer {
          //filter: saturate(1);
        }

       // x.icon .icon-background {fill:var(--bg-color)}

        `}
      </style>
      <div className='grid grid-flow-row gap-4 auto-rows-[1fr] grid-cols-5'>
        {resultItems?.map((item, i) => {
          const isActive = item.isRefined // || currentRefinement.length == 0
          const hasItems = item.count > 0 
      
          console.log('rli', item)

          return (<TopicButton item={item} isActive={isActive} hasItems={hasItems} key={i} onClick={()=>refine(item.value)}/>)
        })}
      </div>
    </>
  )
}



export default  connectRefinementList(RefinementButtonList)
