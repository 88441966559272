import { useEffect } from 'react'
import { Link } from 'react-router-dom'
import {
  ContextProductAttribute,
  ProductAttributeGroup,
} from '../../common/AttributeGroup'
import { ICourseraRecord } from '../../search-hits/models/ICourseraRecord'
//import { CreateCourseDetailViewModel } from "../canvas/CanvasCourseDetail"
import { CourseBanner } from '../CourseBanner'
import { CourseDetailLabels } from '../CourseDetail'
import { IProductView } from '../IProductView'

interface IPropertyObject<T = any> {
  [index: string]: T
}

interface ICourseraHitRecord {
  short_description: string
}

interface ICourseDetailViewModel<T> {
  id: string
  imageUrl: string
  attributes: any[]
  name: string
  entityTypeDescription: string
  textSections: IPropertyObject<{ title: string; text: string }>
  actions: any
  externalRecord: T
  externalProps: any
}

/**
 * Prefixes URL with base URL when protocol is missing and URL is not null.
 * @param url
 * @param base
 * @returns
 */
function correctUrl(url: string | null, base?: string | null) {
  if (url == null) return null
  if (url.match(/^https?:\/\//i)) return url

  if (base == null) return null
  try {
    const fullUrl = `${base}/${url}`
  } catch {
    return null
  }
}

// CSS Class Constants
const attrContainerClassName = `
  attributes-container
  order-2
  min-w-[240px] 
  flex-shrink
  border-2 border-gray-400 bg-gray-100 rounded-lg
  sm:max-w-[260px]`

/**
 * REACT Component: CourseraCourseDetail
 * @param props Displays Course Detail for a Coursera Course
 * @returns
 */
export function CourseraCourseDetail(props: {
  product: IProductView<ICourseraRecord>
}) {
  useEffect(() => {
    // trigger layout refresh
    const recalcLayout = (window as any).recalcLayout
    if (recalcLayout != null) {
      recalcLayout()
    }
  })

  const { product } = props
  console.log('CourseraCourseDetail.tsx: props.product', product)

  //const model: ICourseDetailViewModel<ICourseraHitRecord> =
  const model: ICourseDetailViewModel<any> =
    CreateCourseDetailViewModel(product)
  console.log('CourseraCourseDetail.tsx: model', model)
  console.log('CourseraCourseDetail.tsx: model:actions', model.actions)

  model.actions = {
    ...model.actions,
    enroll: {
      title: 'Enroll Now',
      text: 'Register',
      url: product.external_url,
      className: 'text-[navy]',
    },
  }

  /**
   * REACT Subcomponent: Link to Search Page with preset facets
   * @param facetId name of the facet
   * @param facetValue facet value
   * @param label link text
   * @returns
   */
  function searchFilterLink(
    facetId: string,
    facetValue: string,
    label?: string
  ) {
    const queryKey = encodeURIComponent(`refinementList[${facetId}][0]`)
    const queryValue = encodeURIComponent(facetValue)
    const url = `/search?${queryKey}=${queryValue}`
    return <Link to={url}>{label ?? facetValue}</Link>
  }

  return (
    <div
      className='course-detail course-detail-coursera'
      data-course-id={model.id}
    >
      {/** Course Detail Banner */}
      <CourseBanner
        title={model.name}
        imageUrl={model.imageUrl}
        categoryName={model.entityTypeDescription}
        actions={model.actions}
      />

      {/** Course Detail Body */}
      <div className='body mx-auto max-w-screen-lg '>
        {/** Course Detail Section: Main */}
        <div
          className='
                    flex flex-col flex-wrap gap-x-4 p-4
                    sm:flex-row sm:flex-nowrap sm:items-start'
        >
          {/* Conditionally render Attributes Column */}
          {product.attributes && (
            /* Attributes Container */
            <div
              className='
              order-2
              flex
              min-w-[240px] flex-col
              gap-y-4 '
            >
              {/* <ProductAttributeGroup attributes={product.attributes} header="Course Properties" auto></ProductAttributeGroup> */}
              <div className={attrContainerClassName}>
                <div className=''>
                  <ProductAttributeGroup attributes={product.attributes}>
                    <ProductAttributeGroup.Item
                      label='Topic'
                      value={
                        product.attributes
                          .find(a => a.label == 'CMN_Topics')
                          ?.items?.map((topic: string) =>
                            topic.toString().indexOf('=>') == -1
                              ? searchFilterLink('acad_topic.lvl0', topic)
                              : `${topic.toString()} `
                          ) ?? <div className='text-gray-400'>[unindexed]</div>
                      }
                    />
                    {/* <ContextProductAttribute label="Topics" attributeId="CMN_TOPICS" /> */}
                    {/* <ContextProductAttribute label="Topics (CRSA)" attributeId="CRSA_TOPIC" /> */}
                    <ContextProductAttribute
                      label='Platform'
                      attributeId='CMN_LMS'
                    />
                    {/* <ContextProductAttribute label="Provider" attributeId="CMN_PROVIDER" /> */}
                  </ProductAttributeGroup>
                </div>

                <div className='hidden'>
                  <ProductAttributeGroup attributes={product.attributes}>
                    {/* needs formatter */}

                    <ContextProductAttribute
                      label='Scheduling'
                      attributeId='CMN_SCHEDULING_CONTINUOUS'
                    />
                    {/* <ContextProductAttribute label="Cost" attributeId="CMN_COST_2" /> */}
                  </ProductAttributeGroup>
                </div>

                <div className=''>
                  <ProductAttributeGroup
                    attributes={product.attributes}
                    header='Course Properties'
                  >
                    <ContextProductAttribute
                      label='Difficulty'
                      attributeId='CMN_DIFFICULTY'
                    />
                    {/* <ContextProductAttribute label="Difficulty CRSA" attributeId="CRSA_PRODUCTDIFFICULTYLEVEL" /> */}
                    <ContextProductAttribute
                      label='Duration'
                      attributeId='CMN_COURSE_DURATION'
                    />
                    {/* <ContextProductAttribute label="Duration CRSA" attributeId="CRSA_PRODUCTDURATIONENUM" /> */}
                    <ContextProductAttribute
                      label='Languages'
                      attributeId='CMN_ALL_LANGUAGES'
                    />
                    <ContextProductAttribute
                      label='Average Learning Hours'
                      attributeId='CRSA_AVGLEARNINGHOURS'
                    />
                  </ProductAttributeGroup>
                </div>
              </div>
            </div>
          )}

          {/* Main Column */}
          <div className='main-body order-1 shrink grow'>
            <header className='p-2'>
              <h3 className='text-3xl'>{product.name}</h3>
            </header>

            <div className='section-teaser p-2'>
              {product.external_record.external_record.Tagline}
            </div>

            {/* 
                        <div className="section-description p-2">
                            <h3 className="text-xl">Description</h3>
                            <div className="section-description-raw-html" dangerouslySetInnerHTML={{__html: courseGroup.description}}/>
                        </div> 
                        */}

            <div className={` section-main p-2`}>
              <h3 className='py-2 text-xl'>Description</h3>
              <div
                className='section-description-raw-html'
                dangerouslySetInnerHTML={{
                  __html: product.description.split('\\n').join('</br>'),
                }}
              />
            </div>

            {/* Conditionally render TextSections */}
            {model.textSections &&
              Object.keys(model.textSections).map(key => {
                const section = (model.textSections as any)[key]
                return (
                  <div key={key} className={` section-${key} p-2`}>
                    <h3 className='py-2 text-xl'>{section.title}</h3>
                    <div
                      className='section-description-raw-html'
                      dangerouslySetInnerHTML={{ __html: section.text }}
                    />
                  </div>
                )
              })}

            {/* Action Buttons */}
            <div
              className='cta 
                            align-content-center  
                            my-4 
                            flex
                            flex-col 
                            items-center 
                            gap-2
                            md:flex-row
                            md:flex-wrap 
                            md:justify-center 
                            md:justify-items-stretch'
            >
              <a
                className='citl-btn citl-btn-register min-w-[240px] max-w-[240px]  md:min-w-[120px]'
                href={product.external_url}
              >
                {CourseDetailLabels.RegisterButtonLabel}
              </a>
              {/* <a
                className="citl-btn citl-btn-secondary min-w-[240px] md:min-w-[120px] max-w-[240px]"
                href={model.externalProps?.objUrl}
              >
                {CourseDetailLabels.LearnMoreButtonLabel}
              </a> */}
              <Link
                className='citl-btn citl-btn-secondary min-w-[240px] max-w-[240px]  md:min-w-[120px]'
                to='/search'
              >
                {CourseDetailLabels.SearchButtonLabel}
              </Link>
            </div>

            {/*
            {
                (() => {
                    const sectionRecord = courseGroup.textSections as Record<string,{title?: string, index?: string, text:string}>
                    return <div>{sectionRecord.requirements.title} Records: {sectionRecord.requirements.text}</div>
                })()
            }

            {
    
                Object.entries(courseGroup.textSections)
                    .filter(([_key, value]) => value as {text: string} != null)
                    .map(([key, value]) => {
                        return (
                            <div key={key} className={`section-${key} p-2 text-red-800`}>
                            <h3 className="text-xl">{value.title}</h3>
                            {value.text}
                        </div>
                        )
                    })
                
            }
        */}

            {/* <div className="section-instructors border-t-2 pb-6">
                <header className="p-2"><h3 className="text-xl">Instructors</h3></header>
                <div className="p-2">
                {
                    courseGroup.instructors.map(instructor =>
                        <div key={stringHash(instructor.name)}>
                            { instructor.name }
                        </div>)
                }
                </div>
            </div> */}

            {/* <div className="overflow-x-scroll">
                <h4>sampleData</h4>
                <div>{JSON.stringify(canvasCatalogCourses)}</div>
            </div> */}
          </div>
        </div>

        {/* <div className="section-related-items flex flex-col border-t-2 pb-6">
                <header className="p-2"><h3 className="text-xl">RELATED COURSES (to {activeCourseId})</h3></header>
                    { activeCourseId && <RelatedCourses
                        recommendClient = {recommendClient!}
                        searchIndex={algoliaProductsIndex}
                        objectID={activeCourseId}/>
                    }
            </div> */}
        {/*
                            <div className="section-related-items flex flex-col border-t-2 pb-6">
                                <header className="p-2"><h3 className="text-xl">You might also like</h3></header>
                                <div className="item-slider p-2 flex flex-row flex-nowrap fl gap-2">
                                    {
                                        courseGroup.relatedItems.map(item =>
                                            <div
                                                key={stringHash(item.title)}
                                                className="h-48 w-40 border-4">
                                            {item.title}
                                            </div>
                                        )
                                    }
                                </div>
            
                            </div> */}

        {/* <div className="section-promoted-items flex flex-col border-t-2 pb-6">
                <header className="p-2"><h3 className="text-xl">Promoted Items</h3></header>
                <div className="item-slider p-2 flex flex-row flex-nowrap fl gap-2">
                    {
                        courseGroup.promotedItems.map(item =>
                        <div
                            key={stringHash(item.title)}
                            className="h-48 w-40 border-4">
                            {item.title}
                        </div>)
                    }
                </div>
            </div> */}

        {/* <div className="section-sharing flex flex-col border-t-2 pb-6">
                <header className="p-2">
                    <h3 className="text-xl">Sharing</h3>
                </header>
                <p className="text-center font-bold font-size-lg py-6">
                 Know someone who would like this course?<br />
                 Share it with them!
                </p>
                <div className="item-slider p-2 flex flex-row flex-nowrap fl gap-2 justify-center">
                    <div className="border-blue-600 border-2 rounded-lg px-4 py-2"><a href="#">Twitter</a></div>
                    <div className="border-blue-600 border-2 rounded-lg px-4 py-2"><a href="#">Facebook</a></div>
                    <div className="border-blue-600 border-2 rounded-lg px-4 py-2"><a href="#">Linked In</a></div>
                </div>
            </div> */}
      </div>
      <footer />
    </div>
  )
}

/**
 * Maps a Search Result Document to a Coursera CourseDetail ViewModel
 * @param product
 * @returns
 */
function CreateCourseDetailViewModel(
  product: IProductView<any>
): ICourseDetailViewModel<any> {
  const viewModel: ICourseDetailViewModel<any> = {
    id: product.id,
    imageUrl: product.imageUrl ?? '',
    attributes: [
      {
        label: 'Category',
        items: ['Coursera'],
      },
    ],
    name: product.name,
    entityTypeDescription: product.entityTypeDescription,
    textSections: {},
    actions: [],
    externalRecord: {},
    externalProps: {},

    /*
    id: product.id,
    imageUrl: product.imageUrl,
    name: product.name,
    entityTypeDescription: product.entityTypeDescription,

    _entryStatus: product._entryStatus,
    allLanguages: product.allLanguages,
    citl_audiences: product.citl_audiences,
    "citl_category.lvl1": product["citl_category.lvl1"],
    "citl_category.lvl2": product["citl_category.lvl2"],

    citl_creditStatus: product.citl_creditStatus,
    citl_crs_type: product.citl_crs_type,
    citl_deliveryMethod: product.citl_deliveryMethod,

    citl_status: product.citl_status,
    description: product.description,
    collectionNames: product.collectionNames,
    citl_lmsPlatforms: product.citl_lmsPlatforms,
    productType: product.productType,
    externalProps: {
      objUrl: product.externalProps?.objUrl,
    },
    externalRecord: {
      objUrl: product.externalRecord?.objUrl,
      credits: product.externalRecord?.credits,
      enrollment_fee: product.externalRecord?.enrollment_fee,
      enrollment_open: product.externalRecord?.enrollment_open,
      listing_image: product.externalRecord?.listing_image,
      listing_url: product.externalRecord?.listing_url,
      short_description: product.externalRecord?.short_description,
      description: product.externalRecord?.description,
      tag_names: product.externalRecord?.tag_names,

      title: product.externalRecord?.title,
      type: product.externalRecord?.type,
      updated_at: product.externalRecord?.updated_at,
      visibility: product.externalRecord?.visibility,
    },

    actions: {
      enroll: {
        title: "Register",
        text: "Register",
        url: product.externalProps?.objUrl,
      },
    },

    textSections: {
      description: {
        title: "Description",
        text: product.externalRecord?.description,
      },
    },

    attributes: [
      {
        label: "Category",
        items: [
          "Course",
          "Coursera Course",
          `${product.citl_creditStatus}`,
          `${product.citl_crs_type}`,
        ],
      },

      {
        label: "Topics",
        items: [
          product.externalRecord?.topic,
          product.externalRecord?.subtopic,
        ],
      },
      // {label: "Title", items: course.title?.toString()},
      // {label: "Short Description", items: course.short_description?.toString()},
      {
        label: "Open for Enrollment",
        items: product.externalRecord?.enrollment_open?.toString(),
      },

      {
        label: "Credits",
        items: product.externalRecord?.isCreditEligible
          ? product.externalRecord?.credits ?? "Yes"
          : "No",
      },
      // {label: "Measurement", items: course.measurement?.toString()},
      // {label: "Enrollment", items: [
      //     <PropertyPair key="1" label="Capacity" value={product.externalRecord?.enrollment_cap} />,
      //     <PropertyPair key="2" label="Waitlist Available" value={BooleanToText(product.externalRecord?.waitlist)} />,
      //     <PropertyPair key="3" label="Waitlist Size" value={product.externalRecord?.waitlist_cap} />,
      // ]},
      // {label: "Enrollment Capacity", items: [course.enrollment_cap?.toString()]},
      // {label: "Waitlist Available", items: course.waitlist?.toString()},
      // {label: "Waitlist Capacity", items: course.waitlist_cap?.toString()},
      // {
      //     label: "Enrollment Fee", items: [
      //         `${Number.parseFloat(product.externalRecord?.enrollment_fee).toFixed(2)} ${product.externalRecord?.currency}`,
      //         //course.allowed_payment_types
      //     ]
      // },
      {
        label: "Is Course Free",
        items: BooleanToText(product.externalRecord?.isCourseFree),
      },

      {
        label: "Difficulty Level",
        items: product.externalRecord?.productDifficultyLevel,
      },
      { label: "Duration", items: product.externalRecord?.durationEnum },

      {
        label: "Average Learning Hours",
        items: product.externalRecord?.avgLearningHours,
      },

      { label: "Languages", items: product.externalRecord?.allLanguages },

      { label: "Instructors", items: product.externalRecord?.instructors },

      // {label: "Allowed Payment Types", items: course.allowed_payment_types },
      { label: "Tags", items: product.externalRecord?.tag_names },

      // { label: "Enroll", items: <a href={product.externalRecord?.listing_url}>View in Canvas Catalog</a> },
      // {label: "Listing Image", items: <img className="max-h-72px p-2" src={course.listing_image} alt={course.image_alt_text} />},
      // {label: "image_alt_text", items: course.image_alt_text?.toString()},
      // {label: "Listing Path", items: course.listing_path?.toString()},
      // {label: "show_free_banner", items: course.show_free_banner?.toString()},
      {
        label: "Coursera Catalog",
        items: (
          <div className="text-xs">{product.externalRecord?.facetObjectId}</div>
        ),
      },
      // {label: "Canvas Course ID", items: course.canvas_course.id },
      // {
      //     label: "Metadata", items: [
      //         <PropertyPair key="1" label="Visibility" value={product.externalRecord?.visibility?.toString()} />,
      //         // <PropertyPair key="2" label="Created" value={new Date(parseInt(product.externalRecord?.created_at)/1000).toLocaleDateString()} />,
      //         // <PropertyPair key="3" label="Updated" value={new Date(parseInt(product.externalRecord?.updated_at)/1000).toLocaleDateString()} />,
      //     ]
      // }
    ] as Array<{ label: string; items: any }>,
    */
  }

  return viewModel
}
