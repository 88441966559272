import { ICanvasRecord } from '../search-hits/models/ICanvasRecord'
import { ICourseraRecord } from '../search-hits/models/ICourseraRecord'
import { CanvasCourseDetail } from './canvas/CanvasCourseDetail'
import { CourseraCourseDetail } from './coursera/CourseraCourseDetail'
import { EdxCourseDetail } from './edx/EdxCourseDetail'
import { GenericCourseDetail } from './GenericCourseDetail'
import { IProductView } from './IProductView'

export const CourseDetailLabels = {
  RegisterButtonLabel: 'Enroll',
  LearnMoreButtonLabel: 'Learn More',
  SearchButtonLabel: 'Back to Search',
}

/**
 * Displays a CourseDetail record using a component appropriate for
 * the record's product type.
 * @param props
 * @returns
 */
export function CourseDetail(props: { product: IProductView<unknown> | null }) {
  const { product } = props
  // console.log('CourseDetail.tsx:props.product', product)

  if (product == null) return <div>Product is null</div>

  const productType = (product as any).metadata._record_type as string | null
  if (productType == null) return <div>ProductType is null</div>

  switch (productType) {
    case 'SearchRecord<CourseraCatalogCourse>': {
      // console.log(productType, product)
      const courseraCourseView = product as IProductView<ICourseraRecord>
      return <CourseraCourseDetail product={courseraCourseView} />
    }

    case 'SearchRecord<CanvasCatalogCourse>': {
      // console.log(productType, product)
      const canvasCourseView = product as IProductView<ICanvasRecord>
      return <CanvasCourseDetail product={canvasCourseView} />
    }

    case 'SearchRecord<EdxCourseMetadata>': {
      // console.log(productType, product)
      const canvasCourseView = product as IProductView<ICanvasRecord>
      return <EdxCourseDetail product={canvasCourseView} />
    }

    case 'SearchRecord<GenericCourse>': {
      // console.log(productType, product)
      const canvasCourseView = product as IProductView<ICanvasRecord>
      return <GenericCourseDetail product={canvasCourseView} />
    }

    default:
      return <div>Course Detail Component not found for type {productType}</div>
  }
}
