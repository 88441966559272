import React, { ReactNode } from "react"

export class ErrorBoundary extends React.Component<{children?:ReactNode|ReactNode[]}, {hasError:boolean}> {

  state = { hasError: false }

  static getDerivedStateFromError(error: unknown) {
    // Update state so the next render will show the fallback UI.
    console.log(`EB getDerivedStateFromError error<${typeof(error)}>: ${error}`, error)
    return { hasError: true }
  }

  componentDidCatch(error:unknown, errorInfo:React.ErrorInfo) {
    // You can also log the error to an error reporting service
    if(error instanceof Error) {
      console.log(`EB componentDidCatch error<${typeof(error)}>: ${error.message}`, {error, errorInfo})
    } else {
      console.log(`EB componentDidCatch error<${typeof(error)}>: ${error}`, {error, errorInfo})
    }
  }

  render() {
    const {children} = this.props
    const {hasError} = this.state
    if (hasError) {
      // You can render any custom fallback UI
      return <h1>Something went wrong. Check console</h1>
    }

    return children 
  }
}