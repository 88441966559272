export const entityTypeLabelMap =new Map([
  ["COURSE","Coursera Courses"],
  ["CANVAS/CANVAS-CATALOG-COURSE","Canvas Courses"],
  ["TEST/TEST-DOCUMENT","Custom Offerings"],
])

export const lmsLabelMap =new Map([
  ["COURSERA","Coursera"],
  ["CANVAS", "Canvas"],
])

export const FromSnakeCaseToNormal = (value:string):string => {
  return value.replace("_", " ").replace("[ ]{2,}", "")
}

// replace sequence of non-digits and non-letters with a single char
export const FromNormalToSnakeCase = (value:string):string => {
  return value.replace(/\s+/, "_")
}

// add space before each sequence of capital letters
export const FromPascalToNormal = (value:string):string => {
  return value.replace("[A-Z]{2,}", " $1").replace("[ ]{2,}", "")
}

// capitalize forst letter in each word
export const FromNormalToTitle = (value:string):string => {
  return value.replace(/\b\S/g, (match) => match.toUpperCase())
}

export function Translate<T>(value:string, map: Map<string,T>, fnNormalize?:(a:string)=>string) {
  const normalize = fnNormalize ?? ((a) => FromNormalToSnakeCase(a).toLowerCase())
  const match = map.get(normalize(value)) ?? value
  return match
}

export const Maps = {
  entityTypeLabels: entityTypeLabelMap,
  lmsPlatformLabels: lmsLabelMap
} 