export const usdCurrencyFormatter = (decimals = 2) =>  new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  maximumFractionDigits: decimals,
  minimumFractionDigits: decimals,
  
})

export const toCurrencyString = (value:any, decimals = 2) => {

  const formatter:Intl.NumberFormat = usdCurrencyFormatter(decimals)

  if (value == null) return null

  if(typeof value == "boolean" )
    return value? "yes" : "no"

  if(typeof value == "string" ) {
    const numValue = parseFloat(value)
    if (isNaN(numValue)) { 
      return value 
    }
    return formatter.format(numValue)
  }

  if(typeof value == "number") {
    if (isNaN(value)) { 
      return null
    }
    return formatter.format(value)
  }
      
  return value
}

export const BooleanToText = (arg:any, trueVal = "Yes", falseVal = "No", undefinedVal = null ) => {
  if (arg == null) return undefinedVal
  const bVal = (arg as boolean)
  return bVal ? trueVal : falseVal
} 


